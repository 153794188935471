import React, { useState, useEffect } from "react";
import step1 from "../Assets/Dockifyimages/step1.png";
import step2 from "../Assets/Dockifyimages/step2.png";
import step3 from "../Assets/Dockifyimages/step3.png";
import step4 from "../Assets/Dockifyimages/step4.png";
import step5 from "../Assets/Dockifyimages/step5.png";
import UpgradeButton from "../UpgradeButton/UpgradeButton";
import { useNavigate } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
} from "@mui/material";

interface Step {
  title: string;
  image: string;
  content: string;
}

interface StepperProps {
  isSidebarOpen: boolean;
}

const StepperComponent: React.FC<StepperProps> = ({ isSidebarOpen }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const navigate = useNavigate();
  const steps: Step[] = [
    {
      title: "Products",
      image: step1,
      content:
        "Start by selecting the desired products, then click the 'Add' button to create a new inventory.",
    },
    {
      title: "Inventory",
      image: step2,
      content:
        "Pick the filter, select items from your inventory, and eliminate any unwanted products",
    },
    {
      title: "Profit Margin",
      image: step3,
      content:
        "Click on the profit margin, enter your desired profit range, and you will find an edit option",
    },
    {
      title: "Store",
      image: step4,
      content:
        "Click on your store, retrieve your store URL, and then connect the store",
    },
    {
      title: "Sync",
      image: step5,
      content:
        "Sync your data across devices and platforms to ensure consistency and accessibility.",
    },
  ];

  useEffect(() => {
    const storedCompletedSteps = localStorage.getItem("completedSteps");
    if (storedCompletedSteps) {
      setCompletedSteps(JSON.parse(storedCompletedSteps));
    } else {
      setCompletedSteps(steps.map((_, index) => index + 1));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("completedSteps", JSON.stringify(completedSteps));
  }, [completedSteps]);

  const handleBack = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => {
      const nextStep = Math.min(prevStep + 1, steps.length);
      if (!completedSteps.includes(nextStep)) {
        setCompletedSteps((prevCompletedSteps) => [
          ...prevCompletedSteps,
          nextStep,
        ]);
      }
      return nextStep;
    });
  };

  const handleFinish = () => {
    navigate("/products");
  };

  return (
    <div className="px-4 sm:px-8 md:px-16 py-0">
      <div className="text-start mt-5">
        <span className="dockify-searchheading font-bold">DASHBOARD</span>
      </div>
      <UpgradeButton />
      {steps.map((step, index) => (
        <div
          key={index}
          className={currentStep === index + 1 ? "block" : "hidden"}
        >
          <Paper
            sx={{ padding: 2, marginBottom: 2, width: "100%" }}
            className="w-full"
          >
            <Stepper activeStep={currentStep - 1} className="mb-4">
              {steps.map((step, index) => (
                <Step
                  key={index}
                  completed={completedSteps.includes(index + 1)}
                >
                  <StepLabel className="truncate">{step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <img src={step.image} alt={step.title} className="w-full" />
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              {step.content}
            </Typography>
          </Paper>

          <div className="flex justify-between mt-4">
            <Button
              disabled={currentStep === 1}
              onClick={handleBack}
              sx={{ marginRight: 2 }}
            >
              Back
            </Button>
            {currentStep < steps.length ? (
              <Button onClick={handleNext}>Next</Button>
            ) : (
              <Button onClick={handleFinish}>Finish</Button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepperComponent;
