import { combineReducers } from "redux";
import {
  UPDATE_SELECTED_LINE_ITEM_TYPE,
  UPDATE_SELECTED_STOCK_STATUS,
  UPDATE_SELECTED_SUB_CATEGORY,
  UPDATE_CURRENT_PAGE,
  STORE_FETCHED_DATA,
  STORE_TAG_NAME,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_INVENTORY_SUCCESS,
  SET_SEARCH_RESULTS,
} from "./actions";

const selectedFiltersReducer = (state = {}, action: any) => {
  switch (action.type) {
    case UPDATE_SELECTED_LINE_ITEM_TYPE:
      return {
        ...state,
        selectedLineItemType: action.payload,
      };
    case UPDATE_SELECTED_STOCK_STATUS:
      return {
        ...state,
        selectedStockStatus: action.payload,
      };
    case UPDATE_SELECTED_SUB_CATEGORY:
      return {
        ...state,
        selectedSubCategory: action.payload,
      };
    default:
      return state;
  }
};

const currentPageReducer = (state = 1, action: any) => {
  switch (action.type) {
    case UPDATE_CURRENT_PAGE:
      return action.payload;
    default:
      return state;
  }
};

const fetchedDataReducer = (state = [], action: any) => {
  switch (action.type) {
    case STORE_FETCHED_DATA:
      return action.payload;
    default:
      return state;
  }
};

const initialState = {
  tagName: "",
  addedTagNames: [],
};

const tagNameReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case STORE_TAG_NAME:
      return {
        ...state,
        tagName: action.payload,
        addedTagNames: [...state.addedTagNames, action.payload],
      };
    default:
      return state;
  }
};

const productsReducer = (state = [], action: any) => {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const inventoryReducer = (state = [], action: any) => {
  switch (action.type) {
    case FETCH_INVENTORY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const searchReducer = (
  state = { searchResults: [], paginationData: null },
  action: any
) => {
  switch (action.type) {
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload.results,
        paginationDatas: action.payload.paginationDatas,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  selectedFilters: selectedFiltersReducer,
  currentPage: currentPageReducer,
  fetchedData: fetchedDataReducer,
  tagName: tagNameReducer,
  products: productsReducer,
  inventory: inventoryReducer,
  search: searchReducer,
});

export default rootReducer;
