import { faFilter, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import {
  fetchDockifyData,
  fetchProducts,
  fetchProductsByTitle,
} from "../../Services/Service";
import { message } from "antd";

import { useSelector, useDispatch } from "react-redux";

import ProductCard from "./ProductCard";
import { useNavigate } from "react-router-dom";
import Pagination from "../../PaginationComponent/PaginationComponent";
import {
  setSearchResults,
  storeFetchedData,
  updateCurrentPage,
  updateSelectedLineItemType,
  updateSelectedStockStatus,
  updateSelectedSubCategory,
} from "../../Services/actions";
import Modal from "../../Modal/Modal";
import LoadingSkeleton from "../../LoaderSkeleton/LoadingSkeleton";
import Drawer from "../../Drawer/Drawer";
import ProductSearchBar from "./ProductSearchBarProps";

interface Product {
  id: number;
  dockifySku: any;
  title: string;
  imageUrl: string[];
  subCategory: string;
  price: number;
  lineItemType: string;
  stockStatus: string;
  supplierName: string;
}
interface ApiResponse {
  list: Product[];
}
interface Category {
  type: string;
  name: string;
}
interface RootState {
  selectedFilters: {
    selectedLineItemType: string | null;
    selectedStockStatus: string | null;
    selectedSubCategory: string | null;
  };
  currentPage: number;
  fetchedData: Product[];
}
interface DrawerItem {
  lineItemType: string;
  stockStatus: string;
  subCategory: string;
}
interface ProductsFiltPageProps {
  isSidebarOpen?: boolean;
}

const ProductsFiltPage: React.FC<ProductsFiltPageProps> = ({
  isSidebarOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [products, setProducts] = useState<Product[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());
  const [modalOpen, setModalOpen] = useState(false);
  const [spending, setSpending] = useState<number>(0);
  const [maxSpending, setMaxSpending] = useState<number>(0);
  const [lineItemTypes, setLineItemTypes] = useState<string[]>([]);
  const [stockStatuses, setStockStatuses] = useState<string[]>([]);
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const [paginationData, setPaginationData] = useState<any>(null);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [tagName, setTagName] = useState("");
  const [showTagInput, setShowTagInput] = useState(false);
  const navigate = useNavigate();
  const [tags, setTags] = useState<string[]>([]);
  const [checkBoxLength, setCheckBoxLength] = useState<number>(0);
  const [showAllLineItemTypes, setShowAllLineItemTypes] = useState(false);
  const [showCountLineItemTypes, setShowCountLineItemTypes] = useState(2);
  const [showAllSubCategories, setShowAllSubCategories] = useState(false);
  const [showCountSubCategories, setShowCountSubCategories] = useState(2);
  const scrollRef = useRef<HTMLDivElement>(null);

  const [selectedImages, setSelectedImages] = useState<{
    [key: number]: number;
  }>({});
  const selectedLineItemType = useSelector(
    (state: RootState) => state.selectedFilters.selectedLineItemType ?? ""
  );
  const selectedStockStatus = useSelector(
    (state: RootState) => state.selectedFilters.selectedStockStatus ?? ""
  );
  const selectedSubCategory = useSelector(
    (state: RootState) => state.selectedFilters.selectedSubCategory ?? ""
  );

  const dispatch = useDispatch();
  const currentPage = useSelector((state: RootState) => state.currentPage);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    fetchDataDrawer();
  };

  useEffect(() => {
    fetchDataFromDockify(currentPage);
    console.log("useEffect triggered");
  }, [currentPage]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [products]);

  const fetchDataFromDockify = async (page: any) => {
    setLoading(false);
    try {
      const subCategory = selectedSubCategory || "";
      const lineItemType = selectedLineItemType || "";
      const stockStatus = selectedStockStatus || "";
      const responseData = await fetchDockifyData(
        subCategory,
        lineItemType,
        spending,
        maxSpending,
        stockStatus,
        page
      );

      if (responseData) {
        setProducts(responseData.list || []);
        setPaginationData(responseData);
        setTotalPages(
          Math.ceil(responseData.totalProductCount / responseData.perPage)
        );
        dispatch(storeFetchedData(responseData.list || []));
      } else {
        console.error("Data is empty or undefined");
      }
    } catch (error) {
      console.error("Error fetching data from Dockify API:", error);
    } finally {
      setLoading(true);
    }
  };

  const handlePaginationChange = ({ selected }: { selected: number }) => {
    const pageNumber = selected + 1;
    dispatch(updateCurrentPage(pageNumber));
  };

  const fetchDataDrawer = async () => {
    try {
      const { list }: { list: DrawerItem[] } = await fetchProducts(1, 1000);
      if (!list)
        throw new Error("Failed to retrieve data or invalid data structure.");

      const lineItemTypesSet = new Set<string>();
      const stockStatusesSet = new Set<string>();
      const subCategoriesSet = new Set<string>();

      list.forEach(({ lineItemType, stockStatus, subCategory }) => {
        lineItemTypesSet.add(lineItemType);
        stockStatusesSet.add(stockStatus);
        subCategoriesSet.add(subCategory);
      });

      setLineItemTypes(Array.from(lineItemTypesSet));
      setStockStatuses(Array.from(stockStatusesSet));
      setSubCategories(Array.from(subCategoriesSet));
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    if (selectedLineItemType !== null) {
      dispatch(updateSelectedLineItemType(selectedLineItemType));
    }
    if (selectedStockStatus !== null) {
      dispatch(updateSelectedStockStatus(selectedStockStatus));
    }
    if (selectedSubCategory !== null) {
      dispatch(updateSelectedSubCategory(selectedSubCategory));
    }
  };

  const handleButtonClick = async (event: any) => {
    setIsOpen(false);
    event.preventDefault();
    setLoading(false);
    try {
      const subCategory = selectedSubCategory || "";
      const lineItemType = selectedLineItemType || "";
      const stockStatus = selectedStockStatus || "";
      const responseData = await fetchDockifyData(
        subCategory,
        lineItemType,
        spending,
        maxSpending,
        stockStatus,
        currentPage
      );

      if (responseData) {
        setProducts(responseData.list || []);
        setPaginationData(responseData);
        setTotalPages(
          Math.ceil(responseData.totalProductCount / responseData.perPage)
        );
        dispatch(storeFetchedData(responseData.list || []));
      } else {
        console.error("Data is empty or undefined");
      }
    } catch (error) {
      console.error("Error fetching data from Dockify API:", error);
    } finally {
      setLoading(true);
    }
  };

  const handleSearchButtonClick = async (searchTerm: string) => {
    try {
      const searchData = await fetchProductsByTitle(searchTerm);
      dispatch(
        setSearchResults(searchData.list, {
          totalPages: Math.ceil(
            searchData.totalProductCount / searchData.perPage
          ),
        })
      );
      setProducts(searchData.list);
      setPaginationData(
        Math.ceil(searchData.totalProductCount / searchData.perPage)
      );
    } catch (error) {
      console.error("Error fetching search data:", error);
    }
  };

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);

    if (!selectAll) {
      const allProductIds = products.map((product) => product.dockifySku);
      setSelectedItems(new Set(allProductIds));
    } else {
      setSelectedItems(new Set());
    }
  };

  useEffect(() => {
    setCheckBoxLength(selectedItems.size);
  }, [selectedItems]);

  const handleAddButtonClick = () => {
    if (selectedItems.size === 0) {
      message.warning("Please select product");
    } else {
      setModalOpen(true);
    }
  };

  const handleLineItemTypeChange = (value: any) => {
    dispatch(
      updateSelectedLineItemType(value === selectedLineItemType ? null : value)
    );
  };

  const handleStockStatusChange = (value: any) => {
    dispatch(
      updateSelectedStockStatus(value === selectedStockStatus ? null : value)
    );
  };

  const handleSubCategoryChange = (value: any) => {
    dispatch(
      updateSelectedSubCategory(value === selectedSubCategory ? null : value)
    );
  };

  const handleProductSelect = (dockifySku: any) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);

      if (newSelectedItems.has(dockifySku)) {
        newSelectedItems.delete(dockifySku);
      } else {
        newSelectedItems.add(dockifySku);
      }

      const allProductsSelected = products.every((product) =>
        newSelectedItems.has(product.dockifySku)
      );
      setSelectAll(allProductsSelected);

      return newSelectedItems;
    });
  };

  const handleLeftArrowClick = (index: any) => {
    setSelectedImages((prevSelectedImages) => ({
      ...prevSelectedImages,
      [index]:
        (prevSelectedImages[index] || 0) === 0
          ? products[index].imageUrl.length - 1
          : (prevSelectedImages[index] || 0) - 1,
    }));
  };

  const handleRightArrowClick = (index: any) => {
    setSelectedImages((prevSelectedImages) => ({
      ...prevSelectedImages,
      [index]:
        ((prevSelectedImages[index] || 0) + 1) %
        products[index].imageUrl.length,
    }));
  };

  const gridColumns = isSidebarOpen
    ? "xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1"
    : "xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1";
  const responsiveGridColumns = `${gridColumns} p-5 sm:p-10 md:p-16 gap-10 bg-white`;

  const pageCount = paginationData
    ? Math.ceil(paginationData.totalProductCount / 20)
    : 0;

  const openModal = (modalId: string) => {
    setModalOpen(true);
  };

  const closeModal = (modalId: string) => {
    setModalOpen(false);
  };

  const handleAddTag = () => {
    setShowTagInput(true);
  };

  const handleTagInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(e.target.value);
  };
  const handleConfirm = () => {
    if (tagName.trim() !== "") {
      setTags([...tags, tagName]);
      setTagName("");
      setShowTagInput(false);
    }
  };
  const handleShowMoreLineItemTypes = () => {
    setShowAllLineItemTypes(true);
    setShowCountLineItemTypes(lineItemTypes.length);
  };

  const handleShowLessLineItemTypes = () => {
    setShowAllLineItemTypes(false);
    setShowCountLineItemTypes(2);
  };

  const handleShowMoreSubCategories = () => {
    setShowAllSubCategories(true);
    setShowCountSubCategories(subCategories.length);
  };

  const handleShowLessSubCategories = () => {
    setShowAllSubCategories(false);
    setShowCountSubCategories(2);
  };
  return (
    <div className="relative" ref={scrollRef}>
      <div className="relative py-0">
        <div className="sticky top-0 z-10 text-start bg-[#F4F5F8]">
          <ProductSearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            handleSearchButtonClick={handleSearchButtonClick}
            handleAddButtonClick={handleAddButtonClick}
            toggleDrawer={toggleDrawer}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
            productsLength={products.length}
            totalProductCount={paginationData?.totalProductCount || 0}
            checkBoxLength={checkBoxLength}
          />

          <div className="px-5 sm:px-10 md:px-16 flex justify-between mt-2 text-xs p-2">
            <div>
              {searchTerm
                ? `Showing Search Results: ${products.length} of ${
                    paginationData?.totalProductCount || 0
                  }`
                : `Showing Results: ${Math.min(
                    (currentPage - 1) * 20 + 1,
                    paginationData?.totalProductCount || 0
                  )}-${Math.min(
                    currentPage * 20,
                    paginationData?.totalProductCount || 0
                  )} of ${paginationData?.totalProductCount || 0}`}
            </div>
            <div className="text-xs">
              Selected products: {checkBoxLength || 0}
            </div>
          </div>
        </div>
        <div>
          {!loading ? (
            <LoadingSkeleton gridColumns={responsiveGridColumns} />
          ) : (
            <>
              <div
                className={`grid ${gridColumns} p-5 sm:p-10 md:p-16 gap-10 `}
              >
                {products
                  .filter((product: any) =>
                    product.title
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                  .map((product: any, index: any) => (
                    <ProductCard
                      isOpen={isOpen}
                      key={product.dockifySku}
                      product={product}
                      index={index}
                      selectedImages={selectedImages}
                      selectedItems={selectedItems}
                      handleProductSelect={handleProductSelect}
                      handleLeftArrowClick={handleLeftArrowClick}
                      handleRightArrowClick={handleRightArrowClick}
                    />
                  ))}
              </div>
              <div className="flex justify-center my-8 page-item">
                <div className="flex justify-center my-8 p-4">
                  <Pagination
                    pageCount={pageCount}
                    onPageChange={handlePaginationChange}
                    initialPage={currentPage - 1}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <Drawer
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
          lineItemTypes={lineItemTypes}
          selectedLineItemType={selectedLineItemType}
          handleLineItemTypeChange={handleLineItemTypeChange}
          stockStatuses={stockStatuses}
          selectedStockStatus={selectedStockStatus}
          handleStockStatusChange={handleStockStatusChange}
          subCategories={subCategories}
          selectedSubCategory={selectedSubCategory}
          handleSubCategoryChange={handleSubCategoryChange}
          handleButtonClick={handleButtonClick}
          showAllLineItemTypes={showAllLineItemTypes}
          handleShowMoreLineItemTypes={handleShowMoreLineItemTypes}
          handleShowLessLineItemTypes={handleShowLessLineItemTypes}
          showCountLineItemTypes={showCountLineItemTypes}
          showAllSubCategories={showAllSubCategories}
          handleShowMoreSubCategories={handleShowMoreSubCategories}
          handleShowLessSubCategories={handleShowLessSubCategories}
          showCountSubCategories={showCountSubCategories}
        />
        <div>
          <Modal
            modalOpen={modalOpen}
            closeModal={closeModal}
            tags={tags}
            handleAddTag={handleAddTag}
            showTagInput={showTagInput}
            tagName={tagName}
            handleTagInputChange={handleTagInputChange}
            handleConfirm={handleConfirm}
            selectedItems={selectedItems}
            selectAll={selectAll}
          />
        </div>
      </div>
    </div>
  );
};
export default ProductsFiltPage;
