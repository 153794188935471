import React, { useEffect, useState } from "react";
import {
  fetchSellerDetails,
  fetchUserData,
  logout,
  updateUserData,
} from "../../Services/Service";
import UpgradeButton from "../../UpgradeButton/UpgradeButton";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";

interface PageProductsProps {
  isSidebarOpen: boolean;
}

const Settings: React.FC<PageProductsProps> = ({ isSidebarOpen }) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [inventoryId, setInventoryId] = useState<any>("");
  console.log(inventoryId);
  const sellerIdString = localStorage.getItem("id");
  const sellerId = sellerIdString ? parseInt(sellerIdString) : 0;
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    id: inventoryId,
    sellerId: sellerId,
    sellerName: "",
    email: "",
    phone: "",
    address: "",
    country: "",
  });
  console.log();

  const fetchData = async () => {
    const data = await fetchUserData();

    if (data) {
      setUserData(data);
      console.log(data);
    }
  };

  useEffect(() => {
    const fetchDataAndSellerDetails = async () => {
      await fetchData();
      // await fetchSellerDetails(sellerId, setData, setInventoryId, setLoading);
      console.log(inventoryId);
    };
  
    fetchDataAndSellerDetails();
  }, [sellerId]);
  
  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const registrationDate = new Date().toISOString();
    const updatedUserData = { ...userData, registrationDate };
    try {
      const response = await updateUserData(updatedUserData);
      console.log(response);
      if (response && response.ack === "Success") {
        message.success("User data saved successfully!");
        console.log(response.ack);
      } else {
        message.success("Saved Successfully!");
      }
    } catch (error) {
      console.error("Error updating user data:", error);
      message.error(
        "An error occurred while saving user data. Please try again later."
      );
    }
  };

  const handleLogout = () => {
    logout(setError, navigate);
  };
  return (
    <div className="px-4 sm:px-8 md:px-16 py-2">
      <div className="m-0 p-0">
        <div className="text-start mt-5">
          <span className="dockify-searchheading font-bold">SETTING</span>
        </div>
        <div className="m-0 p-0">
          <UpgradeButton />
        </div>
      </div>
      <form className="bg-white " onSubmit={handleSubmit}>
        <div className="flex justify-between bg-white p-4">
          <div className="p-2 font-bold">Account Information</div>
          <div>
            <button
              className="getstared text-md font-bold   rounded-lg px-5 py-2.5 text-center w-full"
              type="button"
              onClick={handleLogout}
            >
              Log Out
            </button>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-6 p-4 sm:p-6">
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="sellerName"
              className="text-md font-bold text-gray-900 block mb-2"
            >
              User Name
            </label>
            <input
              type="text"
              name="sellerName"
              id="sellerName"
              value={userData.sellerName}
              onChange={handleInputChange}
              className="border rounded block w-full p-3 outline-none"
              placeholder="Seller Name"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="email"
              className="text-md font-bold text-gray-900 block mb-2"
            >
              Email
            </label>
            <input
              type="text"
              name="email"
              id="email"
              value={userData.email}
              onChange={handleInputChange}
              className="border rounded block w-full p-3 outline-none"
              placeholder="Email"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="phone"
              className="text-md font-bold text-gray-900 block mb-2"
            >
              Phone
            </label>
            <input
              type="text"
              name="phone"
              id="phone"
              value={userData.phone}
              onChange={handleInputChange}
              className="border rounded block w-full p-3 outline-none"
              placeholder="Phone"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="address"
              className="text-md font-bold text-gray-900 block mb-2"
            >
              Address
            </label>
            <input
              type="text"
              name="address"
              id="address"
              value={userData.address}
              onChange={handleInputChange}
              className="border rounded block w-full p-3 outline-none"
              placeholder="Address"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="country"
              className="text-md font-bold text-gray-900 block mb-2"
            >
              Country
            </label>
            <input
              type="text"
              name="country"
              id="country"
              value={userData.country}
              onChange={handleInputChange}
              className="border rounded block w-full p-3 outline-none"
              placeholder="Country"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor="registrationDate"
              className="text-md font-bold text-gray-900 block mb-2"
            >
              Registration Date
            </label>
            <input
              type="text"
              name="registrationDate"
              id="registrationDate"
              // value={userData.registrationDate}
              onChange={handleInputChange}
              className="border rounded block w-full p-3 outline-none"
              placeholder="Registration Date"
            />
          </div>
        </div>
        <div className="flex justify-end bg-white p-4">
          <div>
            <button
              className="getstared font-bold rounded-lg text-md px-5 py-2.5 text-center w-full"
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Settings;
