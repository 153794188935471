import React, { useState } from "react";

interface ProfitMarginModalProps {
  isModalOpen: boolean;
  editData: any;
  isDefaultSelected: boolean;
  handleOptionChange: (e: any) => void;
  handleCategoryClick: (category: string) => void;
  handleConfirm: () => void;
  handleEditButtonClick: () => void;
  closeModal: () => void;
  isVisible: boolean;
  setEditData: (data: any) => void;
}

const ProfitMarginModal: React.FC<ProfitMarginModalProps> = ({
  isModalOpen,
  editData,
  isDefaultSelected,
  handleOptionChange,
  handleCategoryClick,
  handleConfirm,
  handleEditButtonClick,
  closeModal,
  setEditData,
  isVisible,
}) => {
  const [message, setMessage] = useState("");

  return (
    <div className="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 flex justify-center items-center">
      <div className="w-full h-full overflow-auto">
        <div className="mx-auto w-full max-w-3xl xl:max-w-4xl shadow-xl rounded-md bg-white p-10">
          <div className="relative flex justify-end">
            <button
              onClick={closeModal}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-md ml-auto inline-flex items-center"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                ></path>
              </svg>
            </button>
          </div>
          <div>
            <h5 className="border-b inline-block border-black font-bold">
              Add to Profit Margin
            </h5>
            <p className="text-sm py-2">
              If you click on the 'Default' or 'Custom' profit margin option,
              which profit margin do you want to apply to the selected
              categories before saving?
            </p>
          </div>
          <div className="text-left p-3 flex flex-col md:flex-row">
            <div className="md:w-1/2 md:pr-4">
              <div className="flex gap-10 items-center ">
                <div className="flex gap-3">
                  <div>
                    <input
                      type="radio"
                      id="specifyColor"
                      value="Default"
                      checked={isDefaultSelected}
                      onChange={handleOptionChange}
                    />
                  </div>
                  <div className="font-bold">Default</div>
                </div>
                <div className="flex gap-3">
                  <div>
                    <input
                      type="radio"
                      id="specifyColor"
                      value="Custom"
                      checked={!isDefaultSelected}
                      onChange={handleOptionChange}
                    />
                  </div>
                  <div className="font-bold">Custom</div>
                </div>
              </div>

              {isDefaultSelected ? (
                <div>
                  <label className="block text-md font-medium text-gray-700">
                    Percentage
                  </label>
                  <input
                    type="text"
                    value={editData.defaultValue}
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        defaultValue: e.target.value,
                      })
                    }
                    className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none "
                  />
                </div>
              ) : (
                <>
                  <div>
                    <label className="block text-md font-medium text-gray-700">
                      Name:
                    </label>
                    <input
                      type="text"
                      value={editData.name}
                      onChange={(e) =>
                        setEditData({ ...editData, name: e.target.value })
                      }
                      className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none"
                    />
                  </div>
                  <div>
                    <label className="block text-md font-medium text-gray-700">
                      From:
                    </label>
                    <input
                      type="number"
                      value={editData.from}
                      onChange={(e) =>
                        setEditData({ ...editData, from: e.target.value })
                      }
                      className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none"
                    />
                  </div>
                  <div>
                    <label className="block text-md font-medium text-gray-700">
                      To:
                    </label>
                    <input
                      type="number"
                      value={editData.to}
                      onChange={(e) =>
                        setEditData({ ...editData, to: e.target.value })
                      }
                      className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none"
                    />
                  </div>
                  <div>
                    <label className="block text-md font-medium text-gray-700">
                      Percentage:
                    </label>
                    <input
                      type="number"
                      value={editData.percentage}
                      placeholder="30"
                      onChange={(e) =>
                        setEditData({
                          ...editData,
                          percentage: e.target.value,
                        })
                      }
                      onBlur={() => {
                        if (parseInt(editData.percentage) < 30) {
                          setEditData({
                            ...editData,
                            percentage: "30",
                          });
                          setMessage("Minimum percentage is 30%");
                        } else {
                          setMessage("");
                        }
                      }}
                      className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none"
                    />
                    {message && (
                      <p className="text-red-500 text-sm mt-1">{message}</p>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="md:w-1/2 md:pl-4">
              <h3 className="text-md mb-4 font-bold">Category Name</h3>
              <div className="flex flex-col md:flex-row justify-start text-md items-center gap-3">
                <div className="md:flex md:items-center md:gap-5">
                  <div className="md:flex md:items-center md:gap-5">
                    {["Gold", "Silver", "Diamond"].map((category) => (
                      <div
                        key={category}
                        className={`${
                          editData.categoryName &&
                          editData.categoryName.includes(category)
                            ? "text-[#ff4a17] bg-gray-200"
                            : ""
                        } md:flex md:items-center md:gap-5`}
                        onClick={() => handleCategoryClick(category)}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className={`border md:p-2 md:rounded ${
                            editData.categoryName &&
                            editData.categoryName.includes(category)
                              ? "bg-[#ff4a17] text-white cursor-not-allowed"
                              : ""
                          }`}
                        >
                          {category}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="gap-5 flex items-end justify-end">
            <div>
              <button
                className="mt-4 px-2 py-2 border text-[#ff4a17] rounded-md focus:outline-none "
                onClick={handleConfirm}
              >
                Skip
              </button>
            </div>

            {isVisible ? (
              <div>
                <button
                  className="mt-4 px-2 py-2 border text-[#ff4a17] rounded-md focus:outline-none"
                  onClick={handleConfirm}
                >
                  Add
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="mt-4 px-2 py-2 border text-[#ff4a17] rounded-md focus:outline-none"
                  onClick={handleEditButtonClick}
                >
                  Edit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitMarginModal;
