import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { message, notification } from "antd";
import {
  fetchData,
  fetchProfitMarginById,
  handleExtraAction,
  handleRemove,
  postData,
  postDataModal,
  updateUserData,
} from "../Services/Service";
import LoadingIndicator from "../LoaderSkeleton/LoadingIndicator";
import LoadingOverlay from "../LoaderSkeleton/LoadingOverlay ";
import emptyImage from "../Assets/Dockifyimages/emptyimg.webp";
import ProfitMarginModal from "../Modal/ProfitMarginModal";

interface ProfitMarginProps {
  isSidebarOpen: boolean;
}

const ProfitMargin: React.FC<ProfitMarginProps> = ({ isSidebarOpen }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState<any>(null);
  const [profitMargins, setProfitMargins] = useState<any[]>([]);
  const [isDefaultSelected, setIsDefaultSelected] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleEdit = async (id: any) => {
    try {
      const data = await fetchProfitMarginById(id);
      setEditData(data);
      setIsModalOpen(true);
      setIsVisible(false);
    } catch (error) {
      console.error("Error fetching profit margins:", error);
    }
  };

  const fetchProfitMargins = async () => {
    setIsLoading(true);
    try {
      const data = await fetchData();
      if (Array.isArray(data)) {
        setProfitMargins(data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching profit margins:", error);
    }
  };

  useEffect(() => {
    fetchProfitMargins();
  }, []);

  const handleCategoryClick = (category: string) => {
    console.log(category);
    setEditData((prevEditData: any) => {
      const updatedCategories = new Set(prevEditData.categoryName || []);

      if (updatedCategories.has(category)) {
        updatedCategories.delete(category);
      } else {
        updatedCategories.add(category);
      }

      return {
        ...prevEditData,
        categoryName: Array.from(updatedCategories),
      };
    });
  };

  const handleOptionChange = (e: any) => {
    setIsDefaultSelected(e.target.value === "Default");
  };

  const handleAdd = () => {
    const sellerId = localStorage.getItem("id");
    const newEditData = {
      name: "",
      type: "",
      from: "",
      to: "",
      percentage: "",
      categoryName: [],
      sellerId: sellerId,
    };

    setEditData(newEditData);
    console.log("Add button clicked");
    setIsModalOpen(true);
    setIsVisible(true);
  };

  const handleConfirm = async () => {
    setIsLoading(true);

    const sellerId = localStorage.getItem("id");
    console.log(sellerId);
    if (!sellerId) {
      console.error("Seller ID is not available.");
      setIsLoading(false);
      return;
    }

    const dataToSend = {
      ProfitMargin: {
        name: editData.name,
        type: isDefaultSelected ? "Default" : "Custom",
        from: editData.from,
        to: editData.to,
        percentage: editData.percentage,
        categoryName: Array.isArray(editData.categoryName)
          ? editData.categoryName
          : [],
        createdOn: new Date().toISOString(),
        sellerId: sellerId,
      },
    };

    try {
      const response = await postDataModal(dataToSend);
      if (!response.ok) {
        throw new Error("Failed to add profit margin");
      }
      const responseData = await response.json();
      console.log("Profit margin added successfully:", responseData);
      message.success(`Added successfully `);
      notification.success({
        message: `Added successfully `,
      });
      fetchProfitMargins();
      setProfitMargins([...profitMargins, responseData]);
      closeModal();
      setIsVisible(true);
    } catch (error) {
      console.error("Error adding profit margin:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditButtonClick = () => {
    handleExtraAction(editData, fetchProfitMargins, setIsModalOpen);
  };
  return (
    
    <div>
        <div className="px-5 sm:px-8 md:px-16 py-5">
        <span className="dockify-searchheading font-bold"> PROFIT MARGIN</span>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-center px-2 sm:px-10 md:px-14">
        <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-auto md:w-full relative px-2">
          <input
            type="text"
            placeholder="Enter Keywords..."
            className="flex-grow rounded-md px-2 py-1 h-[48px] lg:px-4 lg:py-2 focus:outline-none focus:border-orange-500 sm:w-auto md:w-full w-full"
          />

          <button
            className="absolute font-bold top-0 right-0 mt-2 h-[40px] px-4 py-2 bg-[#ff4a17] text-white rounded-md"
            style={{ transform: "translateY(-10%)", marginRight: "10px" }}
          >
            <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
          </button>
        </div>

        <div className="flex flex-grow w-full font-bold items-center space-x-2 lg:space-x-4 mt-2 sm:mt-0  whitespace-nowrap px-2">
          <button
            className="flex-grow py-2 h-[48px] bg-white rounded"
            onClick={handleAdd}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add
          </button>

          <button className="flex-grow px-3 py-2 h-[48px] bg-white rounded">
            <FontAwesomeIcon icon={faSort} className="mr-2" />
            Sort
          </button>
        </div>
      </div>

      <LoadingOverlay isLoading={isLoading} />
      {profitMargins.length === 0 ? (
        <div className="flex justify-center items-center min-h-screen">
          <img src={emptyImage} alt="No data" className="w-96" />
        </div>
      ) : (
        <div className="px-4 sm:px-8 md:px-16 py-10 ">
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead className="border-b bg-white font-bold">
                <tr>
                  <th className="text-md font-lg text-black border px-6 py-4 text-left">
                    Name
                  </th>
                  <th className="text-md  font-lg text-black border px-6 py-4 text-left">
                    From
                  </th>
                  <th className="text-md  font-lg text-black border px-6 py-4 text-left">
                    To
                  </th>
                  <th className="text-md  font-lg text-black border px-6 py-4 text-left">
                    Percentage
                  </th>
                  <th className="text-md  font-lg text-black border px-6 py-4 text-left">
                    Type
                  </th>
                  <th className="text-md  font-lg text-black border px-6 py-4 text-left">
                    Edit
                  </th>
                  <th className="text-md font-lg text-black border px-6 py-4 text-left">
                    Remove
                  </th>
                </tr>
              </thead>
              <tbody>
                {profitMargins.map((margin, index) => (
                  <tr key={index} className="border bg-white font-bold">
                    <td className="text-md text-black px-6 py-4 whitespace-nowrap border">
                      {margin.name}
                    </td>
                    <td className="text-md text-black px-6 py-4 whitespace-nowrap border">
                      {margin.from}
                    </td>
                    <td className="text-md text-black px-6 py-4 whitespace-nowrap border">
                      {margin.to}
                    </td>
                    <td className="text-md text-black px-6 py-4 whitespace-nowrap border">
                      {margin.percentage}
                    </td>
                    <td className="text-md text-black px-6 py-4 whitespace-nowrap border">
                      {margin.type}
                    </td>
                    <td className="text-md text-black px-6 py-4 whitespace-nowrap border">
                      <button
                        className="text-black border border-black  hover:bg-[#ff4a17] hover:text-white hover:border-[#ff4a17]   rounded-lg text-md px-5 py-2.5 text-center "
                        type="submit"
                        onClick={() => handleEdit(margin.id)}
                      >
                        Edit
                      </button>
                    </td>
                    <td className="text-md text-black px-6 py-4 whitespace-nowrap border ">
                      <button
                        className="text-black border-black font-bold border hover:bg-[#ff4a17] hover:border-[#ff4a17] hover:text-white  rounded-lg text-md px-5 py-2.5 text-center"
                        type="button"
                        onClick={() =>
                          handleRemove(
                            margin.id,
                            setProfitMargins,
                            profitMargins
                          )
                        }
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {isModalOpen && (
        <ProfitMarginModal
          isModalOpen={isModalOpen}
          editData={editData}
          isDefaultSelected={isDefaultSelected}
          handleOptionChange={handleOptionChange}
          handleCategoryClick={handleCategoryClick}
          handleConfirm={handleConfirm}
          handleEditButtonClick={handleEditButtonClick}
          closeModal={closeModal}
          isVisible={isVisible}
          setEditData={setEditData}
        />
      )}
    </div>
  );
};

export default ProfitMargin;
