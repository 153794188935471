import { message, notification } from "antd";

export interface LoginResponse {
  accessToken: string;
  id: string;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const sellerId = localStorage.getItem("id");

export async function login(
  email: string,
  password: string
): Promise<LoginResponse> {
  const response = await fetch(`${API_BASE_URL}/Account/Login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });
  if (response.ok) {
    const data: LoginResponse = await response.json();
    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("id", data.id);
    return data;
  } else {
    throw new Error("Failed to log in. Please check your credentials.");
  }
}

export async function registerUser(
  email: string,
  password: string,
  confirmPassword: string
) {
  try {
    const response = await fetch(`${API_BASE_URL}/Account/Register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, confirmPassword }),
    });
    if (!response.ok) {
      throw new Error("Error registering user");
    }
    return response.json();
  } catch (error) {
    throw new Error("Error registering user");
  }
}

export async function resetPassword(
  email: string
): Promise<{ message: string }> {
  try {
    const response = await fetch(`${API_BASE_URL}/Account/ResetPassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw new Error("An error occurred. Please try again later.");
  }
}

export async function confirmOTP(
  email: string,
  otp: string
): Promise<{ message: string }> {
  try {
    const response = await fetch(`${API_BASE_URL}/Account/ConfirmOTP`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, otp }),
    });

    if (!response.ok) {
      throw new Error("OTP confirmation failed. Please try again.");
    }
    const responseData = await response.text();
    return { message: responseData };
  } catch (error) {
    console.error("Error confirming OTP:", error);
    throw new Error("An error occurred. Please try again later.");
  }
}

export const fetchPaginationData = async (page: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/DockifyProduct/GetPagination?Page=${page}&PerPage=20`
    );
    const data = await response.json();
    if (data && data.list) {
      return data;
    } else {
      console.error("Invalid data structure:", data);
      return null;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const fetchProductsByTitle = async (title: string) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/DockifyProduct/GetByTitle?Title=${encodeURIComponent(
        title
      )}`
    );
    const data = await response.json();
    if (data && data.list) {
      return data;
    } else {
      console.error("Invalid data structure:", data);
      return null;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const fetchProducts = async (page: number, perPage: number) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/DockifyProduct/GetPagination?Page=${page}&PerPage=${perPage}`
    );
    const data = await response.json();
    if (data && data.list) {
      return data;
    } else {
      console.error("Invalid data structure:", data);
      return null;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const fetchUserData = async () => {
  const sellerIdString = localStorage.getItem("id");
  const sellerId = sellerIdString ? parseInt(sellerIdString) : 0;
  try {
    const response = await fetch(
      `${API_BASE_URL}/SellerDetail/Get?SellerId=${sellerId}`
    );
    const { data } = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};

export const confirmClick = async (
  selectedItems: any[],
  inputTagName: string | null
) => {
  try {
    const sellerId = localStorage.getItem("id");
    if (sellerId === null) {
      throw new Error("Seller ID not found in localStorage");
    }
    const dockifySKUArray = Array.from(selectedItems).map(String);
    const response = await fetch(
      `${API_BASE_URL}/SellerInventoryListProduct/Post`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productRequest: {
            id: 0,
            inventoryId: 0,
            inventoryName: inputTagName,
            sellerId: sellerId,
            marginId: 6,
            dockifySKU: dockifySKUArray,
            createdOn: "2024-04-16T09:55:31.278Z",
            isActive: true,
          },
        }),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    console.log(data);

    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateUserData = async (userData: any) => {
  const sellerIdString = localStorage.getItem("id");
  const sellerId = sellerIdString ? parseInt(sellerIdString) : 0;
  try {
    const response = await fetch(`${API_BASE_URL}/SellerDetail/Update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: userData.id,
        sellerId: sellerId,
        name: userData.sellerName,
        email: userData.email,
        phone: userData.phone,
        address: userData.address,
        country: userData.country,
      }),
    });
    const data = await response.json();
    console.log(data.ack);
    return data.ack;
  } catch (error) {
    console.error("Error updating user data:", error);
    return false;
  }
};

export const postData = async ({
  name,
  isCustomSelected,
  from,
  to,
  percentage,
  selectedCategories,
}: {
  name: string;
  isCustomSelected: boolean;
  from: string;
  to: string;
  percentage: string;
  selectedCategories: string[];
}) => {
  try {
    const response = await fetch(`${API_BASE_URL}/SellerProfitMarkup/Post`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        request: "Your request data here",
        ProfitMargin: {
          name: name.trim(),
          type: isCustomSelected ? "Custom" : "Default",
          from: from.trim(),
          to: to.trim(),
          percentage: percentage.trim(),
          categoryName: [selectedCategories.join(",")],
          sellerId: sellerId,
        },
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to submit data");
    }

    const data = await response.json();
    console.log("Data sent successfully:", data);
  } catch (error: any) {
    console.error("Error sending data:", error.message);
  }
};

export const fetchData = async () => {
  const sellerId = localStorage.getItem("id");
  try {
    const response = await fetch(
      `${API_BASE_URL}/SellerProfitMarkup/GetPagination?SellerId=${sellerId}&AscendingToDescending=1&Page=1&PerPage=5`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    return data.data;
  } catch (error: any) {
    console.error("Error fetching data:", error.message);
    return [];
  }
};

export const handleRemove = async (
  id: any,
  setProfitMargins: Function,
  profitMargins: any[]
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/SellerProfitMarkup/Delete?Id=${id}`,
      {
        method: "DELETE",
      }
    );
    if (!response.ok) {
      throw new Error("Failed to remove profit margin");
    }

    const updatedMargins = profitMargins.filter((margin) => margin.id !== id);
    setProfitMargins(updatedMargins);
    console.log("Profit margin removed successfully");
    message.success(`removed successfully `);
    notification.success({
      message: `removed successfully `,
    });
  } catch (error) {
    console.error("Error removing profit margin:", error);
  }
};

export const fetchDockifyData = async (
  category: string,
  product: string,
  spending: number,
  maxSpending: number,
  stockStatus: string,
  page: number
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/DockifyProduct/GetPagination?Page=${page}&PerPage=20&CategoryName=${product}&SubCategoryName=${category}&MinPrice=${
        spending !== 0 ? spending : ""
      }&MaxPrice=${
        maxSpending !== 0 ? maxSpending : ""
      }&StockStatus=${stockStatus}`
    );
    console.log("Response status:", response.status);
    if (response.ok) {
      const data = await response.json();
      console.log("Data from Dockify API:", data);
      return data;
    } else {
      throw new Error(
        `Failed to fetch data from Dockify API: ${response.statusText}`
      );
    }
  } catch (error) {
    console.error("Error fetching data from Dockify API:", error);
    throw error;
  }
};

export async function deleteInventoryItems(data: {
  dockifySKU: string[];
  inventoryId: number;
}): Promise<any> {
  const apiUrl = `${API_BASE_URL}/SellerInventoryListProduct/ParticularInventoryListDelete`;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  } catch (error: any) {
    throw new Error(`Error deleting inventory items: ${error.message}`);
  }
}
export const postDataModal = async (data: any) => {
  try {
    const response = await fetch(`${API_BASE_URL}/SellerProfitMarkup/Post`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const deleteInventoryItem = async (inventoryId: any) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/SellerInventoryList/delete?Id=${inventoryId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    console.log("Inventory item deleted successfully");
  } catch (error) {
    console.error("There was a problem with the delete operation:", error);
  }
};

export const fetchProductsByInventoryId = async (
  inventoryId: any,
  pageNumber: number
) => {
  const sellerId = localStorage.getItem("id");
  const apiUrl = `${API_BASE_URL}/SellerInventoryListProduct/GetInventoryListProductPagination?SellerId=${sellerId}&InventoryId=${inventoryId}&Page=${pageNumber}&PerPage=20`;

  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching products:", error);
    return { data: [], totalPages: 0 };
  }
};

export const fetchDataInventory = async (sellerId: any) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/SellerInventoryList/SellerId?SellerId=${sellerId}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    return [];
  }
};

export const handleDelete = async (
  selectedItemsData: any,
  selectedInventoryId: any
) => {
  const data = {
    dockifySKU: selectedItemsData.dockifySKU,
    inventoryId: selectedInventoryId,
  };

  try {
    const response = await fetch(
      `${API_BASE_URL}/SellerInventoryListProduct/%20ParticularInventoryListDelete`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("There was a problem with your fetch operation:", error);
    return null;
  }
};

export const handleExtraAction = async (
  editData: any,
  fetchProfitMargins: any,
  setIsModalOpen: any
) => {
  console.log("Edit Data:", editData);
  try {
    if (!editData || !Array.isArray(editData.categoryName)) {
      console.error(
        "Category name is not available or not in the expected format."
      );
      return;
    }

    const categoryArray = editData.categoryName.filter(
      (category: any) => typeof category === "string"
    );

    const cleanedCategoryArray = categoryArray
      .map((category: any) => category.trim())
      .filter((category: any) => category.length > 1);

    console.log("Cleaned Category Array:", cleanedCategoryArray);

    const payload = {
      PostProfitMarginMarkupRequest: {
        id: editData.id,
        name: editData.name,
        percentage: editData.percentage,
        to: editData.to,
        from: editData.from,
        categoryName: cleanedCategoryArray,
        sellerId: editData.sellerId,
        type: "category",
      },
    };

    const response = await fetch(`${API_BASE_URL}/SellerProfitMarkup/Update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const responseData = await response.json();
    console.log("Response data:", responseData);

    if (response.ok) {
      console.log("User data updated successfully");
      fetchProfitMargins();
      setIsModalOpen(false);
    } else {
      throw new Error("Failed to update user data");
    }
  } catch (error) {
    console.error("Error updating user data:", error);
  }
};
export const fetchProfitMarginById = async (id: any) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/SellerProfitMarkup/Get?Id=${id}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch profit margins");
    }
    const data = await response.json();
    if (data.ack === "Success") {
      return data.getProfitMarginMarkupdto;
    } else {
      throw new Error("Failed to fetch profit margins");
    }
  } catch (error) {
    console.error("Error fetching profit margins:", error);
    throw error;
  }
};

export const fetchSellerDetails = async (
  setData: any,
  setInventoryId: any,
  setLoading: any,
  setError: any
) => {
  try {
    const sellerIdString = localStorage.getItem("id");
    if (!sellerIdString) {
      setError("Seller ID not found in local storage");
      setLoading(false);
      return;
    }

    const sellerId = parseInt(sellerIdString);
    if (isNaN(sellerId)) {
      setError("Invalid seller ID in local storage");
      setLoading(false);
      return;
    }

    const response = await fetch(
      `${API_BASE_URL}/SellerDetail/Get?SellerId=${sellerId}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const jsonData = await response.json();
    setData(jsonData);
    setInventoryId(jsonData.data.id);
    setLoading(false);
  } catch (error: any) {
    setError(error.message || "An error occurred");
    setLoading(false);
  }
};

export const fetchDataVaildity = async (isAnnual: boolean) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/SellerPaymentPlan/Validity?Validity=${
        isAnnual ? "yearly" : "monthly"
      }`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.lists;
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};
export const logout = (setError: any, navigate: any) => {
  const token = localStorage.getItem("accessToken");

  if (!token) {
    setError("Access token not found in local storage.");
    return;
  }

  fetch(`${API_BASE_URL}/Account/Logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Token: token,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Logout failed");
      }
      localStorage.clear(); // Clear all data in local storage
      navigate("/");
      console.log("Logout successful");
    })
    .catch((error) => {
      setError("Error occurred during logout: " + error.message);
    });
};

