import React, { useEffect, useState, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { message } from "antd";
import { fetchDataVaildity } from "../Services/Service";

const stripePromise = loadStripe(
  "pk_test_51OUjplHnHjydHcHgxY35BMKEnbXr7hHhBtlkfbD2WXBXuHUlBN055ndW5LSEzgOPN8jRZHGBkHEdKKdmQVeHfbmg00YHQKiPWV"
);
interface SubscriptionModalProps {
  onClose: () => void;
}

const Subscription: React.FC<SubscriptionModalProps> = ({ onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAnnual, setIsAnnual] = useState(true);
  const [apiData, setApiData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [cardElement, setCardElement] = useState<any>(null);
  const cardElementRef = useRef<any>(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    openModal();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    onClose();
  };
  const openPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };
  useEffect(() => {
    const fetchPlanData = async () => {
      // setIsLoading(true);
      try {
        const data = await fetchDataVaildity(isAnnual);
        setApiData(data);
      } catch (error: any) {
        console.log(error);
      } finally {
        // setIsLoading(false);
      }
    };

    fetchPlanData();
  }, [isAnnual]);

  useEffect(() => {
    initializeStripe();
  }, []);

  useEffect(() => {
    if (selectedPlan) {
      initializeCardElement();
    }
  }, [selectedPlan]);

  const initializeStripe = async () => {
    try {
      await stripePromise;
    } catch (error) {
      console.error("Error initializing Stripe:", error);
    }
  };

  const initializeCardElement = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe || !cardElementRef.current) {
        console.error("Stripe or card element ref not available");
        return;
      }
      const elements = stripe.elements();
      const card = elements.create("card");
      card.mount(cardElementRef.current);
      setCardElement(card);
    } catch (error) {
      console.error("Error initializing card element:", error);
    }
  };

  const handlePayment = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe || !cardElement) {
        console.error("Stripe or card element is not available");
        return;
      }
      const result = await stripe.createToken(cardElement);
      if (result.error) {
        console.error(result.error.message);
        return;
      }
      const token = result.token;
      if (token) {
        await processPayment(token.id, token.card?.id);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const processPayment = async (
    tokenId: string,
    cardToken: string | undefined
  ) => {
    try {
      const response = await fetch(
        "https://gateway.dockify.co/api/PaymentGateway/StripeCreatePayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tokenId: tokenId,
            cardToken: cardToken,
            planId: selectedPlan?.id?.toString(),
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to process payment");
      }

      const data = await response.json();

      if (response.status === 200) {
        message.success("Payment successful!");
        console.log("Price of selected plan:", selectedPlan.price);
        closeModal();
      } else {
        message.error("Payment failed. Please try again later.");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      message.error("Error processing payment. Please try again later.");
    }
  };

  return (
    <div className="relative font-inter antialiased ">
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-y-auto ">
          <div className=" w-full overflow-y-auto max-h-full">
            <div className="p-4">
              <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-4 rounded bg-white">
                <div className="flex justify-between border-gray-200 p-4">
                  <h2 className="text-xl font-bold"></h2>
                  <button
                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    onClick={closeModal}
                    aria-label="Close modal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex justify-center max-w-[14rem] m-auto mb-8 lg:mb-16">
                  <div className="relative flex w-full p-1 rounded-full bg-[#ff4a17]">
                    <span
                      className="absolute inset-0 m-1 pointer-events-none"
                      aria-hidden="true"
                    >
                      <span
                        className="absolute inset-0 w-1/2 bg-white text-[#ff4a17] rounded-full shadow-sm transform transition-transform duration-150 ease-in-out"
                        style={{
                          transform: isAnnual
                            ? "translateX(0)"
                            : "translateX(100%)",
                        }}
                      ></span>
                    </span>
                    <button
                      className={`relative flex-1 text-sm font-medium h-8 rounded-full transition-colors duration-150 ease-in-out ${
                        isAnnual ? "text-[#ff4a17]" : "text-white"
                      }`}
                      onClick={() => setIsAnnual(true)}
                      aria-pressed={isAnnual}
                    >
                      Yearly{" "}
                      <span
                        className={isAnnual ? "text-[#ff4a17]" : "text-white"}
                      ></span>
                    </button>
                    <button
                      className={`relative flex-1 text-sm font-medium h-8 rounded-full transition-colors duration-150 ease-in-out ${
                        isAnnual ? "text-white" : "text-[#ff4a17]"
                      }`}
                      onClick={() => setIsAnnual(false)}
                      aria-pressed={!isAnnual}
                    >
                      Monthly
                    </button>
                  </div>
                </div>
                {isLoading ? (
                  <div className="flex space-x-2 justify-center items-center bg-white  dark:invert">
                    <span className="sr-only">Loading...</span>
                    <div className="h-4 w-4 bg-[#ff4a17] rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                    <div className="h-4 w-4 bg-[#ff4a17] rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                    <div className="h-4 w-4 bg-[#ff4a17] rounded-full animate-bounce"></div>
                  </div>
                ) : (
                  <div className="grid gap-6 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 items-start">
                    {apiData.map((data: any, index: number) => (
                      <div className="h-full" key={data.id}>
                        <div
                          className="relative flex flex-col h-full p-6 rounded-2xl bg-white border "
                          style={{
                            backgroundColor:
                              index === 1 || index === 2
                                ? "#ff4a17"
                                : "#FFFFFF",
                            color:
                              index === 1 || index === 2 ? "#FFFFFF" : "black",
                          }}
                        >
                          <div className="mb-5">
                            <div className=" dark:text-slate-200 font-semibold mb-1">
                              {data.name}
                            </div>
                            <div className="inline-flex items-baseline mb-2">
                              <span className=" dark:text-slate-200 font-bold text-3xl">
                                ${data.price}
                              </span>
                              <span className=" font-medium">
                                {isAnnual ? "/yr" : "/mo"}
                              </span>
                            </div>
                          </div>

                          <ul className="  text-sm space-y-3">
                            <li className="flex items-center">
                              <span>Products: {data.products}</span>
                            </li>
                            <li className="flex items-center">
                              <span>
                                {index === 3
                                  ? data.connectedStore
                                  : `Connected Store:${data.connectedStore}`}
                              </span>
                            </li>

                            <li className="flex items-center">
                              <span>{data.automatedInventorySync}</span>
                            </li>
                            <li className="flex items-center">
                              <span>{data.automatedOrderSync}</span>
                            </li>

                            <li className="flex items-center">
                              <span>{data.dynamicPriceSettings}</span>
                            </li>

                            <li className="flex items-center">
                              <span>{data.productUpdates}</span>
                            </li>
                            <li className="flex items-center">
                              <span>{data.support}</span>
                            </li>
                            <li className="flex items-center">
                              <button
                                className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-[#ff4a17] px-3.5 py-2.5 text-sm font-medium text-white shadow-sm"
                                onClick={() => {
                                  openPaymentModal();
                                  setSelectedPlan(data);
                                }}
                                style={{
                                  backgroundColor:
                                    index === 1 || index === 2
                                      ? "#FFFFFF"
                                      : "#ff4a17",
                                  color:
                                    index === 1 || index === 2
                                      ? "black"
                                      : "#FFFFFF",
                                }}
                              >
                                Purchase Plan
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {isPaymentModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 ">
          <div className=" bg-white rounded-lg overflow-hidden overflow-y-auto p-5">
            <div className="flex justify-between ">
              <h2 className="text-xl font-bold px-10 p-5">Payment</h2>
              <button
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={() => setIsPaymentModalOpen(false)}
                aria-label="Close modal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div></div>
            <div className="p-4">
              <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-4 rounded bg-white">
                <div
                  id="card-element"
                  className="p-4 border w-[20rem] rounded shadow"
                  ref={cardElementRef}
                ></div>

                <br />
                <div className="text-center flex items-center justify-center font-bold">
                  <div>{selectedPlan.validity}</div>
                </div>
                <br />
                <div className="text-center flex items-center justify-center">
                  <button onClick={handlePayment} className="getstared">
                    Pay: {selectedPlan.price}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;
