import React, { useState, FormEvent } from "react";
import { resetPassword } from "../../Services/Service";
import { message } from "antd";
import { Link, useNavigate } from "react-router-dom";
export default function ResetPasswordPage() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState("");
  const navigate = useNavigate();
  const handleResetPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await resetPassword(email);
      setMessages(data.message);
      message.success(`OTP Send For ${email}`);
      navigate("/verify");
      localStorage.setItem("resetEmail", email);
    } catch (error: any) {
      setMessages(error.message);
      message.error("unsuccessful");
    }
    setIsLoading(false);
  };

  return (
    <div className="container max-w-full mx-auto py-24 px-6 bg-white h-screen">
      <div className="bg-white">
        <div className="max-w-md mx-auto px-6 py-20">
          <div className="relative flex flex-wrap">
            <div className="w-full relative">
              <div className="mt-6">
                <div className="text-center text-3xl">
                  <span className="dockify-logo">DOCKIFY</span>
                </div>

                <form className="mt-8" onSubmit={handleResetPassword}>
                  <div>
                    <div className="dockify-headings font-bold">
                      Register Email
                    </div>
                    <div className="py-2">
                      <input
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="text-md block px-3 py-2 rounded w-full bg-white border border-gray-300 placeholder-gray-600 focus:placeholder-gray-500 focus:bg-white focus:outline-none"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="mt-3 text-lg bg-[#ff4a17] rounded w-full text-white px-6 py-3 block"
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Reset Password"}
                    </button>
                  </div>
                </form>
                <div className="text-center mt-4  ">
                  <p>
                    You Have an account?{" "}
                    <Link
                      to="/login"
                      className="hover:text-[#ff4a17] font-bold"
                    >
                      Sign In
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
