import React from "react";
import { faSearch, faPlus, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ProductSearchBarProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  handleSearchButtonClick: (searchTerm: string) => void;
  handleAddButtonClick: () => void;
  toggleDrawer: () => void;
  selectAll: boolean;
  handleSelectAll: () => void;
  productsLength: number;
  totalProductCount: number;
  checkBoxLength: number;
}

const ProductSearchBar: React.FC<ProductSearchBarProps> = ({
  searchTerm,
  setSearchTerm,
  handleSearchButtonClick,
  handleAddButtonClick,
  toggleDrawer,
  selectAll,
  handleSelectAll,
  productsLength,
  totalProductCount,
  checkBoxLength,
}) => {
  return (
    <div className="sticky top-0 z-10 text-start mt-0 bg-[#F4F5F8]">
      <div className="px-4 sm:px-8 md:px-16 py-5">
        <span className="dockify-searchheading font-bold">SEARCH PRODUCTS</span>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-center px-4 sm:px-8 md:px-16 gap-2">
        <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-auto md:w-1/2 relative">
          <input
            type="text"
            placeholder="Enter Keywords..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full rounded-md px-2 py-1 sm:py-2 h-[48px] lg:px-4 lg:py-2 focus:outline-none focus:border-orange-500"
          />
          <button
            onClick={() => handleSearchButtonClick(searchTerm)}
            className="absolute top-0 font-bold right-0 mt-2 h-[40px] px-4 py-2 bg-[#ff4a17] text-white rounded-md"
            style={{
              transform: "translateY(-10%)",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
          </button>
        </div>

        <div className="flex items-center space-x-2 font-bold lg:space-x-4 mt-2 sm:mt-0 w-full sm:w-auto md:w-1/2">
          <button
            className="flex-grow px-3 py-2 bg-white rounded h-[48px]"
            onClick={handleAddButtonClick}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add
          </button>
          <div className="flex items-center justify-center bg-white rounded h-[48px] px-3 gap-1 sm:gap-2">
            <div className="inline-flex items-center">
              <label
                className="relative flex cursor-pointer items-center rounded-full"
                data-ripple-dark="true"
              >
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  style={{
                    accentColor: "rgb(255, 74, 23)",
                    backgroundColor: "white",
                  }}
                  id="checkbox"
                />
              </label>
            </div>

            <label className="select-all-label whitespace-nowrap">
              Select All
            </label>
          </div>

          <button
            className="flex-grow py-2  h-[48px] bg-white rounded"
            onClick={toggleDrawer}
          >
            <FontAwesomeIcon icon={faFilter} className="mr-2" />
            Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductSearchBar;
