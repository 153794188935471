import React, { useState } from "react";
import Subscription from "../Subscription/Subscription";

const UpgradeButton = () => {
  const [showSubscription, setShowSubscription] = useState(false);

  const handleUpgrade = () => {
    setShowSubscription(true);
  };

  const handleClose = () => {
    setShowSubscription(false);
  };

  return (
    <div>
      {showSubscription && <Subscription onClose={handleClose} />}
      <div className="py-4 sm:py-8">
        <div className="flex justify-center items-center  w-full rounded p-3 bg-white">
          <span className="font-bold">
            Elevate your plan, enhance your benefits
          </span>
          <button
            onClick={handleUpgrade}
            className="text-white ml-2 font-bold bg-[#ff4a17]  rounded-lg text-md px-5 py-2.5 text-center getstared"
          >
            Upgrade
          </button>
        </div>
        <div className="text-sm mt-2 p-0">
          Maximize your benefits with Dockify
        </div>
      </div>
    </div>
  );
};

export default UpgradeButton;
