import React, { useState } from "react";
import UpgradeButton from "../UpgradeButton/UpgradeButton";
import shoppifymain from "../Assets/Dockifyimages/shoppifymain.png";

interface StoreProps {
  isSidebarOpen: boolean;
}

const ShopifyStore: React.FC<StoreProps> = ({ isSidebarOpen }) => {
  const [formData, setFormData] = useState({
    storeUrl: "",
    APIKey: "",
    APIPassword: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const sellerId = localStorage.getItem("id");
    e.preventDefault();
    try {
      const response = await fetch(
        "https://gateway.dockify.co/SellerStoreMap/Post",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storemap: {
              sellerId: sellerId,
              sellingPlatformId: "1",
              storeName: "Shopify",
              storeUrl: formData.storeUrl,
              createdOn: new Date().toISOString(),
              credentials: JSON.stringify({
                APIKey: formData.APIKey,
                APIPassword: formData.APIPassword,
              }),
              defaultQty: 0,
              inventorySync: false,
              inventorySyncSetting: "string",
              isActive: true,
              modifiedOn: new Date().toISOString(),
              orderSync: false,
              orderSyncSetting: "string",
              overrideStoreItemDesc: true,
              overrideStoreItemPrice: true,
              overrideStoreItemTitle: true,
              status: "Completed",
            },
          }),
        }
      );
      if (response.ok) {
        console.log("Data sent successfully!");
      } else {
        console.error("Failed to send data.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="px-4 sm:px-8 md:px-16 py-10 text-md">
      <span className="block font-bold mt-4 md:transform md:translate-y-4 dockify-searchheading">
        SHOPIFY
      </span>
      <UpgradeButton />
      <form onSubmit={handleSubmit}>
        <div className="rounded bg-white flex flex-col sm:flex-row items-center justify-evenly px-4 sm:px-10 md:px-16 py-10">
          <div className="w-full sm:w-1/2 lg:w-1/3">
            <img src={shoppifymain} alt="Shopify Logo" className="w-full" />
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/3">
            <h3 className="text-center sm:text-left font-bold text-wrap">
              Ecommerce Platform with everything you need to sell online.
            </h3>
            <div className="mt-6">
              <input
                type="text"
                name="storeUrl"
                value={formData.storeUrl}
                onChange={handleInputChange}
                className="border rounded w-full p-3 outline-none"
                placeholder="https://storename.myshopify.com"
              />
            </div>
            <div className="mt-4">
              <input
                type="text"
                name="APIKey"
                value={formData.APIKey}
                onChange={handleInputChange}
                className="border rounded w-full p-3 outline-none"
                placeholder="Shopify API Key"
              />
            </div>
            <div className="mt-4">
              <input
                type="text"
                name="APIPassword"
                value={formData.APIPassword}
                onChange={handleInputChange}
                className="border rounded w-full p-3 outline-none"
                placeholder="Shopify API Password"
              />
            </div>
            <div className="flex justify-end sm:w-full mt-6">
              <button
                type="submit"
                className="getstared py-2 px-4 rounded block text-center w-auto"
              >
                Connect Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ShopifyStore;
