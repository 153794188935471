import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface Product {
  id: number;
  dockifySku: any;
  title: string;
  imageUrl: string[];
  subCategory: string;
  price: number;
  lineItemType: string;
  stockStatus: string;
  supplierName: string;
}

interface ProductCardProps {
  isOpen: boolean;
  product: Product;
  index: number;
  selectedImages: { [key: number]: number };
  selectedItems: Set<number>;
  handleProductSelect: (dockifySku: any) => void;
  handleLeftArrowClick: (index: number) => void;
  handleRightArrowClick: (index: number) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  isOpen,
  product,
  index,
  selectedImages,
  selectedItems,
  handleProductSelect,
  handleLeftArrowClick,
  handleRightArrowClick,
}) => {
  const navigate = useNavigate();

  const handlePreviewClick = (productId: any) => {
    navigate(`/productspage/${productId}`);
  };

  return (
    <div
      key={product.dockifySku}
      className={`rounded-lg overflow-hidden bg-white border-x-white ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}
    >
      <div className="relative ">
        <div className="px-4 py-4">
          <div className="inline-flex items-center">
            <label
              className="relative flex cursor-pointer items-center rounded-full p-3"
              data-ripple-dark="true"
            >
              <input
                type="checkbox"
                checked={selectedItems.has(product.dockifySku)}
                onChange={() => handleProductSelect(product.dockifySku)}
                style={{
                  accentColor: "rgb(255, 74, 23)",
                  backgroundColor: "white",
                }}
                id="checkbox"
              />

              <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100"></div>
            </label>
          </div>
        </div>
        <div className="relative w-full h-72  overflow-hidden">
          <div className="absolute inset-0 flex justify-center items-center">
            <img
              className="absolute inset-0 w-full h-full object-cover bg-no-repeat"
              src={product.imageUrl[selectedImages[index] || 0]}
              alt={product.title}
            />
            <div className="absolute inset-0 flex justify-between items-center opacity-0 transition-opacity duration-300 hover:opacity-100">
              <button
                className="text-white bg-[#ff4a17] rounded-full p-2 hover:bg-opacity-90 focus:outline-none"
                onClick={() => handleLeftArrowClick(index)}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
              </button>
              <span
                className="text-black p-2 font-bold bg-opacity-50 rounded-lg cursor-pointer"
                onClick={() => handlePreviewClick(product.id)}
              >
                Preview
              </span>
              <button
                className="text-white bg-[#ff4a17] rounded-full p-2 hover:bg-opacity-90 focus:outline-none"
                onClick={() => handleRightArrowClick(index)}
              >
                <FontAwesomeIcon icon={faChevronRight} className="text-lg" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-4">
        <a
          href="#"
          className="text-md block overflow-hidden whitespace-nowrap truncate font-bold"
        >
          {product.title}
        </a>
        <div>
          by{" "}
          <span className="text-orange-500 text-sm">
            {product.supplierName}
          </span>
        </div>
      </div>

      <div className="flex justify-between px-4 py-2">
        <div className="w-1/2">
          <div className="text-sm font-normal text-gray-900">
            <div className="py-1">{product.price}</div>
            <div>{product.lineItemType}</div>
          </div>
        </div>
        <div className="w-1/2 flex justify-end">
          <div className="text-sm font-normal text-gray-900">
            <div className="py-1">{product.stockStatus}</div>

            <p className="text-gray-500 text-sm">{product.subCategory}</p>
          </div>
        </div>
      </div>
      <div className="px-4 py-2 text-sm">Shipping: 3 to 5 days</div>
    </div>
  );
};

export default ProductCard;
