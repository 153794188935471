import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash, faFilter } from "@fortawesome/free-solid-svg-icons";
import { Popconfirm, message } from "antd";
import InventoryDrawer from "../Drawer/InventoryDrawer";
import noProductsImage from "../Assets/Dockifyimages/emptyimage.webp";
import {
  deleteInventoryItem,
  fetchDataInventory,
  fetchProductsByInventoryId,
  fetchProductsByTitle,
  handleDelete,
} from "../Services/Service";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInventorySuccess,
  fetchProductsSuccess,
  updateCurrentPage,
} from "../Services/actions";
import InventoryCard from "./InventoryCard";
import LoadingIndicator from "../LoaderSkeleton/LoadingIndicator";
import LoadingOverlay from "../LoaderSkeleton/LoadingOverlay ";
import Pagination from "../PaginationComponent/PaginationComponent";
import InventoryHeader from "./InventoryHeader";

interface Product {
  id: number;
  dockifySKU: any;
  title: string;
  imageUrl: string[];
  subCategory: string;
  price: number;
  lineItemType: string;
  stockStatus: string;
  supplierName: string;
  inventoryId: number;
}

interface InventoryItem {
  id: number;
  name: string;
}

interface InventoryProps {
  isSidebarOpen: boolean;
}

const Inventory: React.FC<InventoryProps> = ({ isSidebarOpen }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [checkBoxLength, setCheckBoxLength] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDrawerItems, setSelectedDrawerItems] = useState<string[]>([]);
  const [inventoryData, setInventoryData] = useState<InventoryItem[]>([]);
  const [selectedInventoryId, setSelectedInventoryId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedItemsData, setSelectedItemsData] = useState<{
    dockifySKU: string[];
  }>({ dockifySKU: [] });
  const [paginationData, setPaginationData] = useState<any>(null);
  const [selectedImages, setSelectedImages] = useState<{
    [key: number]: number;
  }>({});
  const scrollRef = useRef<HTMLDivElement>(null);
  const sellerId = localStorage.getItem("id");

  const dispatch = useDispatch();
  const inventory = useSelector((state: any) => state.inventory);

  useEffect(() => {
    fetchDataInventoryAndHandleError();
  }, [sellerId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const inventoryData = await fetchDataInventory(sellerId);
        dispatch(fetchInventorySuccess(inventoryData));
        if (inventoryData.length > 0) {
          const firstInventoryId = inventoryData[0].id;
          setSelectedInventoryId(firstInventoryId);
          await handleFetchProducts(firstInventoryId, currentPage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dispatch, sellerId]);

  useEffect(() => {
    if (selectedInventoryId !== 0) {
      handleFetchProducts(selectedInventoryId, currentPage);
    }
  }, [selectedInventoryId]);

  const fetchDataInventoryAndHandleError = async () => {
    try {
      setIsLoading(true);
      const data = await fetchDataInventory(sellerId);
      console.log(data);
      setInventoryData(data);
    } catch (error: any) {
      message.error("Error fetching data: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFetchProducts = async (
    inventoryId: number,
    pageNumber: number
  ) => {
    try {
      setIsLoading(true);
      const response = await fetchProductsByInventoryId(
        inventoryId,
        pageNumber
      );
      setProducts(response.data);

      setPaginationData({
        totalProductCount: response.total,
        perPage: response.perPage,
      });

      const totalPages = Math.ceil(response.total / response.perPage);
      setTotalPages(totalPages);
      setCurrentPage(pageNumber);
    } catch (error: any) {
      message.error("Error fetching products: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };


  const handleDeleteAction = async () => {
    try {
      setIsLoading(true);
      await handleDelete(selectedItemsData, selectedInventoryId);
      message.success("Successfully Deleted");
      const updatedInventoryData = inventoryData.filter(
        (item) => item.id !== selectedInventoryId
      );
      setInventoryData(updatedInventoryData);
      if (updatedInventoryData.length > 0) {
        const firstInventoryId = updatedInventoryData[0].id;
        setSelectedInventoryId(firstInventoryId);
        await handleFetchProducts(firstInventoryId, currentPage);
      } else {
        setProducts([]);
      }
    } catch (error: any) {
      message.error("Error handling delete operation: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchButtonClick = async () => {
    try {
      const searchData = await fetchProductsByTitle(searchTerm);
      setProducts(searchData.list);
    } catch (error) {
      console.error("Error fetching search data:", error);
    }
  };

  const handleSelectAll = (selectAll: boolean) => {
    if (selectAll) {
      const selectedIds = products.map((product) => product.id);
      const dockifySKUs = products.map((product) => product.dockifySKU);
      setSelectedItems(new Set(selectedIds));
      setSelectedItemsData({ dockifySKU: dockifySKUs.flat() });
    } else {
      setSelectedItems(new Set());
      setSelectedItemsData({ dockifySKU: [] });
    }
  };

  const handleProductSelect = (productId: number) => {
    const product = products.find((p) => p.id === productId);
    if (!product) return;

    const updatedSelectedItems = new Set(selectedItems);
    if (updatedSelectedItems.has(productId)) {
      updatedSelectedItems.delete(productId);
    } else {
      updatedSelectedItems.add(productId);
    }
    setSelectedItems(updatedSelectedItems);

    setSelectedItemsData((prevData) => ({
      ...prevData,
      dockifySKU: [...prevData.dockifySKU, product.dockifySKU],
    }));
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleDrawerCheckboxChange = async (itemName: string) => {
    setSelectedDrawerItems([itemName]);
    const selectedInventory = inventoryData.find(
      (item: InventoryItem) => item.name === itemName
    );
    if (selectedInventory) {
      const selectedInventoryId = selectedInventory.id;
      setSelectedInventoryId(selectedInventoryId);
      try {
        await handleFetchProducts(selectedInventoryId, currentPage);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }
  };
  useEffect(() => {
    if (selectedInventoryId !== 0 && scrollRef.current !== null) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedInventoryId, currentPage]);

  const handleDrawerConfirm = async () => {
    toggleDrawer();

    if (selectedDrawerItems.length === 0) {
      console.log("No items selected in the drawer.");
      return;
    }

    const selectedInventory = inventoryData.find((item: InventoryItem) =>
      selectedDrawerItems.includes(item.name)
    );

    if (!selectedInventory) {
      console.error("Selected inventory not found.");
      return;
    }

    const selectedInventoryId = selectedInventory.id;
    setSelectedInventoryId(selectedInventoryId);

    try {
      await handleFetchProducts(selectedInventoryId, currentPage);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const deleteInventoryBtn = async (inventoryId: number) => {
    try {
      await deleteInventoryItem(inventoryId);

      const updatedInventory = inventoryData.filter(
        (item) => item.id !== inventoryId
      );
      setInventoryData(updatedInventory);

      if (updatedInventory.length > 0) {
        const firstInventoryId = updatedInventory[0].id;
        setSelectedInventoryId(firstInventoryId);
        await handleFetchProducts(firstInventoryId, currentPage);
      } else {
        setProducts([]);
      }

      message.success("Inventory item deleted successfully");
      setIsOpen(false);
    } catch (error: any) {
      message.error("Error deleting inventory item: " + error.message);
    }
  };

  const gridColumns = isSidebarOpen
    ? "xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1"
    : "xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1";

  useEffect(() => {
    setCheckBoxLength(selectedItems.size);

    const allSelected =
      products.length > 0 && selectedItems.size === products.length;

    setSelectAll(allSelected);
  }, [selectedItems, products]);

  const pageCount = paginationData
    ? Math.ceil(paginationData.totalProductCount / 20)
    : 0;
  const handlePaginationChange = async ({ selected }: { selected: number }) => {
    try {
      const pageNumber = selected + 1;
      if (pageNumber !== currentPage) {
        setCurrentPage(pageNumber);
        await handleFetchProducts(selectedInventoryId, pageNumber);
      }
    } catch (error) {
      console.error("Error handling pagination change:", error);
    }
  };

  return (
    <div className="py-0" ref={scrollRef}>
      <div className="sticky top-0 z-10 mt-0 bg-[#F4F5F8]">
      
        <InventoryHeader
          searchTerm={searchTerm}
          selectAll={selectAll}
          handleSearchButtonClick={handleSearchButtonClick}
          handleSelectAll={handleSelectAll}
          handleDeleteAction={handleDeleteAction}
          toggleDrawer={toggleDrawer}
          selectedItemsSize={selectedItems.size}
        />
        <div className="px-5 sm:px-10 md:px-16 flex justify-between mt-2 text-xs">
          <div>
            {searchTerm
              ? `Showing Search Results: ${products.length} of ${
                  paginationData?.totalProductCount || 0
                }`
              : `Showing Results: ${Math.min(
                  (currentPage - 1) * 20 + 1,
                  paginationData?.totalProductCount || 0
                )}-${Math.min(
                  currentPage * 20,
                  paginationData?.totalProductCount || 0
                )} of ${paginationData?.totalProductCount || 0}`}
          </div>
          <div className="text-xs">
            Selected products: {checkBoxLength || 0}
          </div>
        </div>
      </div>
      {products.length === 0 && (
        <div className="flex justify-center items-center min-h-screen">
          <img src={noProductsImage} alt="No products" className="w-96" />
        </div>
      )}
      {isLoading && <LoadingOverlay isLoading={isLoading} />}
      {products.length > 0 && (
        <div className={`grid ${gridColumns} p-5 sm:p-10 md:p-16 gap-10`}>
          {products.map((product, index) => (
            <div key={product.id} className="relative">
              <InventoryCard
                product={product}
                selected={selectedItems.has(product.id)}
                index={index}
                selectedItems={selectedItems}
                selectedImages={selectedImages}
                handleProductSelect={handleProductSelect}
              />
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-center my-8 page-item">
        <div className="flex justify-center my-8 p-4">
          <Pagination
            pageCount={totalPages}
            onPageChange={handlePaginationChange}
            initialPage={currentPage - 1}
          />
        </div>
      </div>
      <InventoryDrawer
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        inventoryData={inventoryData}
        handleDrawerCheckboxChange={handleDrawerCheckboxChange}
        deleteInventoryBtn={deleteInventoryBtn}
        selectedInventoryId={selectedInventoryId}
        selectedItems={selectedItems}
        handleDrawerConfirm={handleDrawerConfirm}
        selectedDrawerItems={selectedDrawerItems}
      />
    </div>
  );
};

export default Inventory;
