import React from "react";
import UpgradeButton from "../UpgradeButton/UpgradeButton";
import shopify from "../Assets/Dockifyimages/shopifyimage.png";
import bigecommerce from "../Assets/Dockifyimages/bigecommerce.webp";
import woocommerce from "../Assets/Dockifyimages/woocommerce.webp";
import ebay from "../Assets/Dockifyimages/ebay.webp";
import { Link } from "react-router-dom";
interface StoreProps {
  isSidebarOpen: boolean;
}

const Store: React.FC<StoreProps> = ({ isSidebarOpen }) => {
  return (
    <div className="px-8 sm:px-8 md:px-16 py-2">
     <div className="text-start mt-5 ">
        <span className="dockify-searchheading font-bold">  MY STORE</span>
      </div>

      <UpgradeButton />
      <div className=" bg-white flex flex-col md:flex-row items-center px-10 p-5">
        <img
          src={shopify}
          alt="Shopify Logo"
          className="w-48 h-32 md:w-60 md:h-40"
        />
        <div className="flex flex-col md:flex-row items-center justify-between w-full md:pl-4 md:pr-4 ">
          <h6 className="ml-2 md:text-left text-lg font-bold">
            {" "}
            Integrate your Shopify account seamlessly with Dockify
          </h6>
          <div className="flex flex-col   md:flex-row mt-4 md:mt-0 md:justify-end">
            <Link
              to="/shopifystore"
              className="getstared  font-bold    py-2 px-4 rounded block text-center md:mr-2 md:mb-0 mb-2"
            >
              Connect
            </Link>
            <Link
              to="/"
              className="getstared  font-bold    py-2 px-4 rounded block text-center md:ml-2"
            >
              Manage
            </Link>
          </div>
        </div>
      </div>
      <div className=" bg-white flex flex-col md:flex-row items-center px-10 mt-5 p-5">
        <img
          src={bigecommerce}
          alt="Shopify Logo"
          className="w-48 h-32 md:w-60 md:h-40"
        />
        <div className="flex flex-col md:flex-row items-center justify-between w-full md:pl-4 md:pr-4">
          <h6 className="ml-2 md:text-left text-lg font-bold">
            Integrate your Shopify account seamlessly with Dockify
          </h6>
          <div className="flex flex-col   md:flex-row mt-4 md:mt-0 md:justify-end">
            <Link
              to="/"
              className="getstared  font-bold    py-2 px-4 rounded block text-center md:mr-2 md:mb-0 mb-2"
            >
              Connect
            </Link>
            <Link
              to="/"
              className="getstared  font-bold    py-2 px-4 rounded block text-center md:ml-2"
            >
              Manage
            </Link>
          </div>
        </div>
      </div>
      <div className=" bg-white flex flex-col md:flex-row items-center px-10 mt-5 p-5">
        <img
          src={woocommerce}
          alt="Shopify Logo"
          className="w-48 h-32 md:w-60 md:h-40"
        />
        <div className="flex flex-col md:flex-row items-center justify-between w-full md:pl-4 md:pr-4">
          <h6 className="ml-2 md:text-left text-lg font-bold">
            Integrate your Shopify account seamlessly with Dockify
          </h6>
          <div className="flex flex-col   md:flex-row mt-4 md:mt-0 md:justify-end">
            <Link
              to="/"
              className="getstared  font-bold    py-2 px-4 rounded block text-center md:mr-2 md:mb-0 mb-2"
            >
              Connect
            </Link>
            <Link
              to="/"
              className="getstared  font-bold    py-2 px-4 rounded block text-center md:ml-2"
            >
              Manage
            </Link>
          </div>
        </div>
      </div>
      <div className=" bg-white flex flex-col md:flex-row items-center px-10 mt-5 p-5">
        <img
          src={ebay}
          alt="Shopify Logo"
          className="w-48 h-32 md:w-60 md:h-40"
        />
        <div className="flex flex-col md:flex-row items-center justify-between w-full md:pl-4 md:pr-4">
          <h6 className="ml-2 md:text-left text-lg font-bold">
            Integrate your Shopify account seamlessly with Dockify
          </h6>
          <div className="flex flex-col   md:flex-row mt-4 md:mt-0 md:justify-end">
            <Link
              to="/"
              className="getstared  font-bold    py-2 px-4 rounded block text-center md:mr-2 md:mb-0 mb-2"
            >
              Connect
            </Link>
            <Link
              to="/"
              className="getstared  font-bold    py-2 px-4 rounded block text-center md:ml-2"
            >
              Manage
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Store;
