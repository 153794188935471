import React, { useState } from "react";
import { registerUser } from "../../Services/Service";
import { message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Signup() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      message.error("Passwords do not match");
      return;
    }
    try {
      const response = await registerUser(email, password, confirmPassword);
      console.log("User registered successfully!");
      message.success("Registration successful");
      navigate("/");
    } catch (error: any) {
      console.error("Error registering user:", error);
      message.error("Registration Unsuccessful");
    }
  };

  return (
    <div>
      <div className="container max-w-full mx-auto py-24 px-6 h-screen bg-white">
        <div className="">
          <div className="max-w-md mx-auto px-6 py-20">
            <div className="relative flex flex-wrap">
              <div className="w-full relative">
                <div className="mt-6">
                  <div className="text-center text-3xl ">
                    <span className="dockify-logo">DOCKIFY</span>
                  </div>
                  <form className="mt-8" onSubmit={handleSubmit}>
                    <div className="mx-auto max-w-lg">
                      <div className="py-2">
                        <input
                          placeholder="Email"
                          type="email"
                          className="text-md block px-3 py-2 rounded w-full bg-white border placeholder-gray-600  focus:placeholder-gray-500 focus:bg-white focus:outline-none"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="py-2">
                        <div className="relative">
                          <input
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            className="text-md block px-3 py-2 rounded w-full bg-white border  placeholder-gray-600  focus:placeholder-gray-500 focus:bg-white focus:outline-none"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div
                            className="absolute inset-y-0 text-gray-400 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="py-2">
                        <div className="relative">
                          <input
                            placeholder="Confirm Password"
                            type={showConfirmPassword ? "text" : "password"}
                            className="text-md block px-3 py-2  w-full bg-white border rounded border-gray-300 placeholder-gray-600  focus:placeholder-gray-500 focus:bg-white focus:outline-none"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <div
                            className="absolute inset-y-0 right-0 text-gray-400 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            <FontAwesomeIcon
                              icon={showConfirmPassword ? faEyeSlash : faEye}
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        className="mt-3 text-lg bg-[#ff4a17] rounded w-full text-white  px-6 py-3 block  hover:text-white"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                  </form>
                  <div className="text-center mt-4  ">
                    <p>
                      Have an account?{" "}
                      <Link
                        to="/"
                        className="hover:text-[#ff4a17] font-bold"
                      >
                        Sign In
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
