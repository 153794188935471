import React from "react";

interface Product {
  id: number;
  dockifySKU: any;
  title: string;
  imageUrl: string[];
  subCategory: string;
  price: number;
  lineItemType: string;
  stockStatus: string;
  supplierName: string;
  inventoryId: number;
}

interface ProductCardProps {
  product: Product;
  selected: boolean;
  index: number;
  selectedImages: { [key: number]: number };
  handleProductSelect: (productId: number) => void;
  selectedItems: Set<number>;
}

const InventoryCard: React.FC<ProductCardProps> = ({
  product,
  selected,
  index,
  selectedImages,
  handleProductSelect,
}) => {
  return (
    <div className="rounded-lg overflow-hidden w-full bg-white border-x-white">
      <div className="relative">
        <div className="px-4 py-4">
          <div className="inline-flex items-center">
            <label className="relative flex cursor-pointer items-center rounded-full p-3">
              <input
                type="checkbox"
                checked={selected}
                onChange={() => handleProductSelect(product.id)}
                className="mr-2"
                style={{ accentColor: "#ff4a17", cursor: "pointer" }}
              />
              <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
               
              </div>
            </label>
          </div>
        </div>
        <div className="relative w-full h-72  overflow-hidden">
          <div className="absolute inset-0 flex justify-center items-center">
            <img
              className="absolute inset-0 w-full h-full object-cover bg-no-repeat"
              src={product.imageUrl[selectedImages[index] || 0]}
              alt={product.title}
            />
            <div className="absolute inset-0 flex justify-between items-center opacity-0 transition-opacity duration-300 hover:opacity-100"></div>
          </div>
        </div>
      </div>
      <div className="px-4 py-4">
        <a
          href="#"
          className="text-md block overflow-hidden whitespace -nowrap truncate font-bold"
        >
          {product.title}
        </a>
        <div>
          by{" "}
          <span className="text-[#ff4a17] text-sm">{product.supplierName}</span>
        </div>
      </div>

      <div className="flex justify-between px-4 py-2">
        <div className="w-1/2">
          <div className="text-sm font-normal text-gray-900">
            <div className="py-1">{product.price}</div>
            <div>{product.lineItemType}</div>
          </div>
        </div>
        <div className="w-1/2 flex justify-end">
          <div className="text-sm font-normal text-gray-900">
            <div className="py-1">{product.stockStatus}</div>
            <p className="text-gray-500 text-sm">{product.subCategory}</p>
          </div>
        </div>
      </div>
      <div className="px-4 py-2 text-sm">Shipping: 3 to 5 days</div>
    </div>
  );
};

export default InventoryCard;
