import React, { useState } from "react";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface DrawerProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  lineItemTypes: string[];
  selectedLineItemType: string;
  handleLineItemTypeChange: (type: string) => void;
  stockStatuses: string[];
  selectedStockStatus: string;
  handleStockStatusChange: (status: string) => void;
  subCategories: string[];
  selectedSubCategory: string;
  handleSubCategoryChange: (category: string) => void;
handleButtonClick: (event: any) => void;
  showAllLineItemTypes: boolean;
  handleShowMoreLineItemTypes: () => void;
  handleShowLessLineItemTypes: () => void;
  showCountLineItemTypes: number;
  showAllSubCategories: boolean;
  handleShowMoreSubCategories: () => void;
  handleShowLessSubCategories: () => void;
  showCountSubCategories: number;
}

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  toggleDrawer,
  lineItemTypes,
  selectedLineItemType,
  handleLineItemTypeChange,
  stockStatuses,
  selectedStockStatus,
  handleStockStatusChange,
  subCategories,
  selectedSubCategory,
  handleSubCategoryChange,
  handleButtonClick,
  showAllLineItemTypes,
  handleShowMoreLineItemTypes,
  handleShowLessLineItemTypes,
  showCountLineItemTypes,
  showAllSubCategories,
  handleShowMoreSubCategories,
  handleShowLessSubCategories,
  showCountSubCategories,
}) => {
  const [spending, setSpending] = useState<number>(0);
  const [maxSpending, setMaxSpending] = useState<number>(0);
  

  return (
    <div>
      {isOpen && (
        <div className="drawer-bg">
          <div className="flex justify-center items-center  bg-white">
            <div
              key="filters"
              className="fixed bg-white top-0 right-0 z-40 w-full h-screen max-w-xs p-4 overflow-y-auto transition-transform flex justify-center"
            >
              <div>
                <h4 className="items-start flex font-bold">Filters</h4>
              </div>
              <div>
                <button
                  type="button"
                  onClick={toggleDrawer}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 ml-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <form className="space-y-4">
                <div className="space-y-4">
                  <label className="text-sm font-bold text-black border-b inline-block border-black mt-10">
                    Category
                  </label>
                  <div className="flex flex-col space-y-4 items-start  mb-2">
                    {lineItemTypes
                      .slice(0, showCountLineItemTypes)
                      .map((type, index) => (
                        <div key={index}>
                          <input
                            type="checkbox"
                            id={`lineItemType${index}`}
                            checked={selectedLineItemType === type}
                            onChange={() => handleLineItemTypeChange(type)}
                            style={{
                              accentColor: "#ff4a17",
                              cursor: "pointer",
                            }}
                          />
                          <label
                            htmlFor={`lineItemType${index}`}
                            className="ml-2"
                          >
                            {type}
                          </label>
                        </div>
                      ))}
                    {lineItemTypes.length > 2 && !showAllLineItemTypes && (
                      <button onClick={handleShowMoreLineItemTypes}>
                        Show more <FontAwesomeIcon icon={faAngleDown} />
                      </button>
                    )}
                    {showAllLineItemTypes && (
                      <button onClick={handleShowLessLineItemTypes}>
                        Show less <FontAwesomeIcon icon={faAngleUp} />
                      </button>
                    )}
                  </div>
                </div>
                <div className="space-y-4">
                  <label className="text-sm font-bold text-black border-b inline-block border-black">
                    Subcategory
                  </label>
                  <div className="flex flex-col space-y-4 items-start">
                    {subCategories
                      .slice(0, showCountSubCategories)
                      .map((category, index) => (
                        <div key={index} className="flex items-center mb-2">
                          <input
                            type="checkbox"
                            id={`subCategory${index}`}
                            checked={selectedSubCategory === category}
                            onChange={() => handleSubCategoryChange(category)}
                            style={{
                              accentColor: "#ff4a17",
                              cursor: "pointer",
                            }}
                          />
                          <label
                            htmlFor={`subCategory${index}`}
                            className="ml-2"
                          >
                            {category}
                          </label>
                        </div>
                      ))}

                    {subCategories.length > 2 && !showAllSubCategories && (
                      <button onClick={handleShowMoreSubCategories}>
                        Show more <FontAwesomeIcon icon={faAngleDown} />
                      </button>
                    )}
                    {showAllSubCategories && (
                      <button onClick={handleShowLessSubCategories}>
                        Show less <FontAwesomeIcon icon={faAngleUp} />
                      </button>
                    )}
                  </div>
                </div>

                <div>
                  <label className="text-sm font-bold text-black border-b inline-block border-black">
                    Min Max
                  </label>

                  <div className="w-full flex flex-col mt-2">
                    $ {spending}
                    <input
                      type="range"
                      id="spendingInput"
                      min="0"
                      max="100"
                      step="1"
                      value={spending}
                      onChange={(e) => setSpending(parseInt(e.target.value))}
                      style={{
                        accentColor: "rgb(255, 74, 23)",
                        backgroundColor: "white",
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-col mt-2">
                    $ {maxSpending}
                    <input
                      type="range"
                      id="maxSpendingInput"
                      min="0"
                      max="1000"
                      step="1"
                      value={maxSpending}
                      onChange={(e) => setMaxSpending(parseInt(e.target.value))}
                      style={{
                        accentColor: "rgb(255, 74, 23)",
                        backgroundColor: "white",
                      }}
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <label className="text-sm font-bold text-black border-b inline-block border-black">
                    Stock Status
                  </label>
                  <div className="flex flex-col space-y-4 items-start mb-2">
                    {stockStatuses.map((status, index) => (
                      <div key={index}>
                        <input
                          type="checkbox"
                          id={`stockStatus${index}`}
                          checked={selectedStockStatus === status}
                          onChange={() => handleStockStatusChange(status)}
                          style={{
                            accentColor: "#ff4a17",
                            cursor: "pointer",
                          }}
                        />
                        <label
                          htmlFor={`stockStatus${index}`}
                          className="ml-2"
                        >
                          {status}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex items-center space-x-5">
                  <button
                    type="button"
                    className="w-1/2 bg-primary-700 border text-[#ff4a17] font-bold py-2.5 px-4 rounded-lg text-sm"
                  >
                    Reset
                  </button>
                  <button
                    className="w-1/2 bg-primary-700 border text-[#ff4a17] font-bold py-2.5 px-4 rounded-lg text-sm"
                    onClick={handleButtonClick}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Drawer;
