import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./Components/Layout/Main/Main";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";
import PageProducts from "./Components/Pages/Products/PageProducts";
import ProductsPage from "./Components/Pages/Products/ProductsPage";
import Settings from "./Components/Pages/Settings/Settings";
import InventorySync from "./Components/Inventory Sync/InventorySync";
import Store from "./Components/Store/Store";
import ShopifyStore from "./Components/StoreShopify/ShopifyStore";
import ProfitMargin from "./Components/ProfitMargin/ProfitMargin";
import Inventory from "./Components/Inventory/Inventory";
import Login from "./Components/LoginFlow/Login/Login";
import Signup from "./Components/LoginFlow/Signup/Signup";
import VerifyEmail from "./Components/LoginFlow/VerifyEmail/VerifyEmail";
import ResetPasswordPage from "./Components/LoginFlow/ResetPassword/ResetPassword";
import VerifyPassword from "./Components/LoginFlow/VerifyPassword/VerifyPasword";
import Stepper from "./Components/Stepper/Stepper";
import ProductsFiltPage from "./Components/Pages/Products/ProductsFiltPage";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebarMenu = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify" element={<VerifyEmail />} />
      <Route path="/reset" element={<ResetPasswordPage />} />
      <Route path="/verifypassword" element={<VerifyPassword />} />
      <Route
        element={
          <Main
            isSidebarOpen={isSidebarOpen}
            toggleSidebarMenu={toggleSidebarMenu}
          />
        }
      >
        <Route
          path="/dashboard"
          element={<Stepper isSidebarOpen={isSidebarOpen} />}
        />
        {/* <Route path="/products" element={<PageProducts />} /> */}
        <Route
          path="/productspage/:productId"
          element={<ProductsPage isSidebarOpen={isSidebarOpen} />}
        />
        <Route
          path="/settings"
          element={<Settings isSidebarOpen={isSidebarOpen} />}
        />
        <Route
          path="/sync"
          element={<InventorySync isSidebarOpen={isSidebarOpen} />}
        />
        <Route
          path="/mystore"
          element={<Store isSidebarOpen={isSidebarOpen} />}
        />
        <Route
          path="/shopifystore"
          element={<ShopifyStore isSidebarOpen={isSidebarOpen} />}
        />
        <Route
          path="/profit"
          element={<ProfitMargin isSidebarOpen={isSidebarOpen} />}
        />
        <Route
          path="/myinventory"
          element={<Inventory isSidebarOpen={isSidebarOpen} />}
        />
        <Route
          path="/products"
          element={<ProductsFiltPage isSidebarOpen={isSidebarOpen} />}
        />{" "}
 
      </Route>
    </Routes>
  );
}

export default App;
