export const UPDATE_SELECTED_LINE_ITEM_TYPE = "UPDATE_SELECTED_LINE_ITEM_TYPE";
export const UPDATE_SELECTED_STOCK_STATUS = "UPDATE_SELECTED_STOCK_STATUS";
export const UPDATE_SELECTED_SUB_CATEGORY = "UPDATE_SELECTED_SUB_CATEGORY";
export const UPDATE_PAGE_NUMBER = "UPDATE_PAGE_NUMBER";
export const UPDATE_MIN_SPENDING = "UPDATE_MIN_SPENDING";
export const UPDATE_MAX_SPENDING = "UPDATE_MAX_SPENDING";
export const STORE_FETCHED_DATA = "STORE_FETCHED_DATA";
export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE";
export const UPDATE_DATA = "UPDATE_DATA";
export const STORE_TAG_NAME = 'STORE_TAG_NAME';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_INVENTORY_SUCCESS = 'FETCH_INVENTORY_SUCCESS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';

export const updateSelectedLineItemType = (value: any) => ({
  type: UPDATE_SELECTED_LINE_ITEM_TYPE,
  payload: value,
});

export const updateSelectedStockStatus = (value: any) => ({
  type: UPDATE_SELECTED_STOCK_STATUS,
  payload: value,
});

export const updateSelectedSubCategory = (value: any) => ({
  type: UPDATE_SELECTED_SUB_CATEGORY,
  payload: value,
});

export const updatePageNumber = (value: any) => ({
  type: UPDATE_PAGE_NUMBER,
  payload: value,
});

export const updateMinSpending = (value: any) => ({
  type: UPDATE_MIN_SPENDING,
  payload: value,
});

export const updateMaxSpending = (value: any) => ({
  type: UPDATE_MAX_SPENDING,
  payload: value,
});

export const storeFetchedData = (data: any) => ({
  type: STORE_FETCHED_DATA,
  payload: data,
});

export const updateCurrentPage = (page:any) => ({
  type: UPDATE_CURRENT_PAGE,
  payload: page,
});
export const updateData = (data: any) => {
  return {
    type: UPDATE_DATA,
    payload: data,
  };
};
export const storeTagName = (tagName:any) => ({
  type: STORE_TAG_NAME,
  payload: tagName,
});
export const fetchProductsSuccess = (products:any) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products,
});

export const fetchInventorySuccess = (inventory:any) => ({
  type: FETCH_INVENTORY_SUCCESS,
  payload: inventory,
});

export const setSearchResults = (results:any, paginationDatas:any) => ({
  type: SET_SEARCH_RESULTS,
  payload: { results, paginationDatas },
});
