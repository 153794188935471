import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineDashboard } from "react-icons/md";
import { ImPriceTag } from "react-icons/im";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { FaStore } from "react-icons/fa6";
import { IoSyncOutline } from "react-icons/io5";
import { IoMdNotifications } from "react-icons/io";
import { FiSettings } from "react-icons/fi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";

interface SidebarProps {
  isSidebarOpen: boolean;
  toggleSidebarMenu: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  isSidebarOpen,
  toggleSidebarMenu,
}) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState<string>(location.pathname);

  const sidebarItems = [
    {
      icon: <MdOutlineDashboard />,
      name: "Dashboard",
      link: "/dashboard",
    },
    {
      icon: <ImPriceTag />,
      name: "Products",
      link: "/products",
    },
    {
      icon: <BsFillBoxSeamFill />,
      name: "Inventory",
      link: "/MyInventory",
    },
    {
      icon: <FaMoneyBillTrendUp />,
      name: "ProfitMargin",
      link: "/profit",
    },
    {
      icon: <FaStore />,
      name: "Store",
      link: "/MyStore",
    },
    {
      icon: <IoSyncOutline />,
      name: "Sync",
      link: "/Sync",
    },
    {
      icon: <FiSettings />,
      name: "Setting",
      link: "/settings",
    },
  ];

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <div className="relative flex h-screen overflow-y-hidden bg-[#f4f5f8]">
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-10 lg:hidden"
          onClick={toggleSidebarMenu}
        ></div>
      )}

      <aside
        className={`fixed inset-y-0 z-20 flex flex-col flex-shrink-0 w-56 max-h-screen overflow-hidden transition-all transform bg-[#f4f5f8] border-r shadow-lg lg:z-auto lg:static lg:shadow-none ${
          !isSidebarOpen ? "-translate-x-full lg:translate-x-0 lg:w-20" : ""
        }`}
      >
        <div
          className={`flex items-center justify-between flex-shrink-0 p-2 ${
            !isSidebarOpen ? "lg:justify-center" : ""
          }`}
        >
          <button
            id="hidebutton"
            onClick={toggleSidebarMenu}
            className="fixed top-2 left-2  lg:left-4 p-4  rounded-md focus:outline-none focus:ring no-ring z-30 "
          >
            <svg
              className="w-4 h-4 text-black outline-none focus:outline-none"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 12h18M3 6h18M3 18h18"
              />
            </svg>
          </button>
        </div>

        <nav className="flex-1 overflow-hidden hover:overflow-y-auto items-center mt-20 p-3">
          <ul className="p-0 m-0">
            {sidebarItems.map((item, index) => (
              <li key={index} className="mb-2 block">
                <Link
                  to={item.link}
                  className={`flex items-center p-[1rem] space-x-2 rounded-md hover:text-[#ff4a17] ${
                    activeLink === item.link ? "text-[#ff4a17]" : ""
                  }`}
                >
                  <div className="flex items-center text-sm font-bold">
                    <span className="w-6 h-6 flex items-center justify-center">
                      {item.icon}
                    </span>
                    {isSidebarOpen && (
                      <span className="ml-2">{item.name}</span>
                    )}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;
