import React, { useState, FormEvent } from "react";
import { confirmOTP } from "../../Services/Service";
import { Link, useNavigate } from "react-router-dom";

export default function VerifyEmail() {
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [resetEmail] = useState(localStorage.getItem("resetEmail") || "");

  const handleVerifyEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await confirmOTP(resetEmail, otp);
      if (response.message === "OTP is Valid") {
        console.log("Before navigation...");
        navigate("/verifypassword");
        console.log("After navigation...");
      } else {
        setMessage("OTP confirmation failed. Please try again.");
      }
    } catch (error: any) {
      setMessage("Error confirming OTP: " + error.message);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <div className="container max-w-full mx-auto py-24 px-6 h-screen bg-white">
        <div className="">
          <div className="max-w-md mx-auto px-6 py-20">
            <div className="relative flex flex-wrap">
              <div className="w-full relative">
                <div className="mt-6">
                  <div className="text-center text-3xl ">
                    <span className="dockify-logo">DOCKIFY</span>
                  </div>
                  <form className="mt-8" onSubmit={handleVerifyEmail}>
                    <div className="mx-auto max-w-lg">
                      <div className="py-2">
                        <input
                          placeholder="Email"
                          type="email"
                          value={resetEmail}
                          onChange={(e) => setEmail(e.target.value)}
                          className="text-md block px-3 py-2 rounded w-full bg-white border placeholder-gray-600 focus:placeholder-gray-500 focus:bg-white focus:outline-none"
                          readOnly
                        />
                      </div>
                      <div className="py-2">
                        <div className="relative">
                          <input
                            placeholder="OTP"
                            type="text"
                            value={otp}
                            onChange={(e) => setOTP(e.target.value)}
                            className="text-md block px-3 py-2 rounded w-full bg-white border placeholder-gray-600 focus:placeholder-gray-500 focus:bg-white focus:outline-none"
                            required
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="mt-3 text-lg bg-[#ff4a17] rounded w-full text-white px-6 py-3 block hover:text-white"
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </form>
                  <div className="text-center mt-4">
                    <p>
                      You have an account?
                      <Link to="/signup" className="hover:text-[#ff4a17] font-bold">
                        Sign In
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
