import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";

interface PaginationProps {
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  initialPage?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  onPageChange,
  initialPage = 0,
}) => {
  const handlePaginationClick = ({ selected }: { selected: number }) => {
    console.log("Selected page:", selected);

    onPageChange({ selected });
  };

  return (
    <div className="flex justify-center my-8 p-4">
      <ReactPaginate
        pageCount={pageCount}
        onPageChange={handlePaginationClick}
        containerClassName={
          "pagination flex flex-wrap justify-center space-x-2 md:space-x-4"
        }
        initialPage={initialPage}
        activeClassName={
          "text-white bg-[#FF4A17] px-3 py-1 rounded-full md:px-4 md:py-2"
        }
        pageClassName={
          "text-gray-700 px-2 py-1 rounded-full cursor-pointer transition-colors duration-300 md:px-3 md:py-2"
        }
        breakClassName={
          "text-gray-700 px-2 py-1 rounded-full cursor-pointer transition-colors duration-300 md:px-3 md:py-2"
        }
        previousClassName={
          "hidden md:block  text-gray-700 px-2 py-1 rounded-full cursor-pointer transition-colors duration-300 md:px-3 md:py-2"
        }
        nextClassName={
          "hidden md:block  text-gray-700 px-2 py-1 rounded-full cursor-pointer transition-colors duration-300 md:px-3 md:py-2"
        }
        disabledClassName={
          "text-gray-400 px-2 py-1 rounded-full md:px-3 md:py-2"
        }
      />
    </div>
  );
};

export default Pagination;
