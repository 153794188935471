import React, { useState } from "react";
import { login } from "../../Services/Service";
import { Link, useNavigate } from "react-router-dom";
import { message, notification } from "antd";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
import LoadingBar from "react-top-loading-bar";

interface LoginResponse {
  accessToken: string;
  id: string;
}

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingApiCall, setIsLoadingApiCall] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setIsLoadingApiCall(true);
    try {
      const data = await login(email, password);
      console.log(data);
      message.success("Login successful");
      console.log("Access Token:", data.accessToken);
      console.log("ID:", data.id);
      navigate("/dashboard");
      notification.success({
        message: `Welcome ${email}`,
      });
    } catch (error: any) {
      setError(error.message);
      message.error("Login Error");
    }
    setIsLoading(false);
    setIsLoadingApiCall(false);
  };

  return (
    <div className="container max-w-full mx-auto py-24 px-6 bg-white h-screen">
      <div className="bg-white">
        <div className="max-w-md mx-auto px-6 py-20 ">
          <div className="relative flex flex-wrap">
            <div className="w-full relative">
              <div className="mt-6">
                <div className="text-center text-3xl">
                  <span className="dockify-logo">DOCKIFY</span>
                </div>
                <form className="mt-8" onSubmit={handleSubmit}>
                  <div>
                    <div className="py-2">
                      <input
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="text-md block px-3 py-2 rounded w-full bg-white border border-gray-300 placeholder-gray-600 focus:placeholder-gray-500 focus:bg-white focus:outline-none"
                        required
                      />
                    </div>
                    <div className="py-2">
                      <div className="relative">
                        <input
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="text-md block px-3 py-2 rounded w-full bg-white border border-gray-300 placeholder-gray-600 focus:placeholder-gray-500 focus:bg-white focus:outline-none"
                          required
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="h-3 text-gray-400 cursor-pointer"
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end py-3">
                      <label className="block">
                        <Link
                          to="/reset"
                          className="hover:text-[#ff4a17] cursor-pointer font-bold border-gray-200 hover:border-gray-400"
                        >
                          Forgot Password?
                        </Link>
                      </label>
                    </div>
                    <label className="block">
                      <input
                        type="checkbox"
                        className="leading-loose mr-2"
                        style={{
                          accentColor: "rgb(255, 74, 23)",
                          backgroundColor: "white",
                        }}
                      />
                      <span className="py-2 text-sm ">Remember Me</span>
                    </label>
                    <button
                      type="submit"
                      className="mt-3 text-lg bg-[#ff4a17] rounded w-full text-white px-6 py-3 block"
                      disabled={isLoading || isLoadingApiCall}
                    >
                      {isLoading ? "Logging in..." : "Login"}
                    </button>
                    {isLoadingApiCall && (
                      <LoadingBar
                        color="#ff4a17"
                        progress={isLoading ? 100 : 0}
                      />
                    )}
                    {error && <div className="text-red-500 mt-2">{error}</div>}
                  </div>
                  <div className="text-center mt-4">
                    <p>
                      Don't have an account?{" "}
                      <Link
                        to="/signup"
                        className="hover:text-[#ff4a17] font-bold"
                      >
                        Sign Up
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
