import React from "react";

const LoadingSkeleton: React.FC<{ gridColumns: string }> = ({ gridColumns }) => {
  return (
    <div className={`grid grid-cols-1 ${gridColumns} sm:grid-cols-3 gap-10`}>
      {[...Array(20)].map((_, index) => (
        <div
          key={index}
          className="rounded overflow-hidden shadow-md border-gray-200"
    
        >
          <div className="animate-pulse relative">
            <div className="flex justify-center items-center">
              <div className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-200 h-12 w-12 rounded-full"></div>
              <div className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 h-12 w-12 rounded-full"></div>
            </div>
            <div className="h-72 bg-gray-200 w-full"></div>
            <div className="px-4 py-4">
              <div className="h-4 bg-gray-200 w-1/2 mb-2"></div>
              <div className="h-3 bg-gray-200 w-3/4"></div>
            </div>
            <div className="px-4 py-2 flex justify-between">
              <div className="w-1/2">
                <div className="h-4 bg-gray-200 w-3/4"></div>
              </div>
              <div className="w-1/2 flex justify-end">
                <div className="h-4 bg-gray-200 w-1/2"></div>
              </div>
            </div>
            <div className="px-4 py-2 text-sm">Shipping: 3 to 5 days</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoadingSkeleton;
