import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash, faFilter } from "@fortawesome/free-solid-svg-icons";
import { Popconfirm } from "antd";

interface InventoryHeaderProps {
  searchTerm: string;
  selectAll: boolean;
  handleSearchButtonClick: () => void;
  handleSelectAll: (checked: boolean) => void;
  handleDeleteAction: () => void;
  toggleDrawer: () => void;
  selectedItemsSize: number;
}

const InventoryHeader: React.FC<InventoryHeaderProps> = ({
  searchTerm,
  selectAll,
  handleSearchButtonClick,
  handleSelectAll,
  handleDeleteAction,
  toggleDrawer,
  selectedItemsSize,
}) => {
  const [searchInput, setSearchInput] = useState<string>(searchTerm);
  return (
    <div className="sticky top-0 z-10 bg-[#F4F5F8]">
      <div className="px-4 sm:px-8 md:px-16 py-5">
        <span className="dockify-searchheading font-bold">INVENTORY</span>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-center px-4 sm:px-8 md:px-16 gap-2">
        <div className="flex items-center mb-2 sm:mb-0 w-full sm:w-auto md:w-1/2 relative">
          <input
            type="text"
            placeholder="Enter Keywords..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="w-full rounded-md px-2 py-1 sm:py-2 h-[48px] lg:px-4 lg:py-2 focus:outline-none focus:border-orange-500"
          />
          <button
            onClick={handleSearchButtonClick}
            className="absolute top-0 font-bold right-0 mt-2 h-[40px] px-4 py-2 bg-[#ff4a17] text-white rounded-md"
            style={{
              transform: "translateY(-10%)",
              marginRight: "5px",
            }}
          >
            <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
          </button>
        </div>

        <div className="flex items-center space-x-2 font-bold lg:space-x-4 mt-2 sm:mt-0 w-full sm:w-auto md:w-1/2">
        <button
  className="flex items-center justify-center whitespace-nowrap bg-white rounded h-12 px-2"
  style={{ maxWidth: "100%" }}
>
  <div className="flex items-center">
    <input
      type="checkbox"
      onChange={(e) => handleSelectAll(e.target.checked)}
      className="mr-2"
      id="checkbox"
      checked={selectAll}
      style={{
        accentColor: "#ff4a17",
        cursor: "pointer",
      }}
    />
    <label className="select-all-label whitespace-nowrap truncate">
      Select All
    </label>
  </div>
</button>

          <div className="flex-grow flex items-center justify-center bg-white rounded h-[48px] px-2 gap-1 sm:gap-2">
            <Popconfirm
              title="Delete the product"
              description="Are you sure to delete this product?"
              okText="Yes"
              cancelText="No"
              onConfirm={handleDeleteAction}
              disabled={selectedItemsSize === 0}
            >
              <button className="flex items-center justify-center whitespace-nowrap bg-white rounded h-12 px-2">
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                Remove
              </button>
            </Popconfirm>
          </div>
          <button
            className="flex-grow flex items-center justify-center whitespace-nowrap overflow-hidden bg-white rounded h-12 px-2 gap-1 sm:gap-2"
            style={{ maxWidth: "100%" }}
            onClick={toggleDrawer}
          >
            <FontAwesomeIcon icon={faFilter} className="mr-2" />
            <span className="truncate">InventoryList</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InventoryHeader;
