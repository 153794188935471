import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Header/Navbar";

interface MainProps {
  isSidebarOpen: boolean;
  toggleSidebarMenu: () => void;
}

const Main: React.FC<MainProps> = ({ isSidebarOpen, toggleSidebarMenu }) => {

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        toggleSidebarMenu={toggleSidebarMenu}
      />
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className="lg:hidden">
          <Navbar
            toggleSidebarMenu={toggleSidebarMenu}
            selectAllProducts={() =>
              console.log("Implement selectAllProducts function")
            }
          />
        </div>
        <div className="flex-1 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Main;
