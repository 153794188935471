import { message } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function VerifyPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [resetEmail] = useState(localStorage.getItem("resetEmail") || "");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const handlePasswordUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://gateway.dockify.co/Account/PasswordUpdate",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: resetEmail, password }),
        }
      );
      if (response.ok) {
        setError(null);
        setPassword("");
        setConfirmPassword("");
        navigate("/");
        message.success("Password successfully Changed");
      } else {
        const data = await response.json();
        setError(data.message || "Password update failed");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      setError("An error occurred while updating password. Please try again.");
    }
    setIsLoading(false);
  };

  return (
    <div className="container max-w-full mx-auto py-24 px-6 bg-white h-screen">
      <div className="bg-white">
        <div className="max-w-md mx-auto px-6 py-20">
          <div className="relative flex flex-wrap">
            <div className="w-full relative">
              <div className="mt-6">
                <div className="text-center text-3xl">
                <span className="dockify-logo">DOCKIFY</span>

                </div>
                <form className="mt-8" onSubmit={handlePasswordUpdate}>
                  <div>
                    <div className="py-2 relative">
                      <input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="text-md block px-3 py-2 rounded w-full bg-white border placeholder-gray-600 focus:placeholder-gray-500 focus:bg-white focus:outline-none"
                        required
                      />
                      <div
                        className="absolute text-gray-400 inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </div>
                    </div>
                    <div className="py-2 relative">
                      <input
                        placeholder="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="text-md block px-3 py-2 rounded w-full bg-white border placeholder-gray-600 focus:placeholder-gray-500 focus:bg-white focus:outline-none"
                        required
                      />
                      <div
                        className="absolute text-gray-400 inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={showConfirmPassword ? faEyeSlash : faEye}
                        />
                      </div>
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    <button
                      type="submit"
                      className="mt-3 text-lg bg-[#ff4a17] rounded w-full text-white px-6 py-3 block"
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </button>
                  </div>
                </form>
                <div className="text-center mt-4  ">
                    <p>
                     Already Have an account?{" "}
                      <Link to="/signup" className="hover:text-[#ff4a17] font-bold">
                        Sign In
                      </Link>
                    </p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
