import { Popconfirm } from "antd";
import React, { useState } from "react";

interface InventoryDrawerProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  inventoryData: any[]; // Update the type as per your data structure
  handleDrawerCheckboxChange: (itemName: string) => void;
  deleteInventoryBtn: (inventoryId: any) => void;
  selectedInventoryId: any; // Update the type as per your data structure
  selectedItems: Set<number>;
  handleDrawerConfirm: () => void;
  selectedDrawerItems: any[];
}

const InventoryDrawer: React.FC<InventoryDrawerProps> = ({
  isOpen,
  toggleDrawer,
  inventoryData,
  handleDrawerCheckboxChange,
  deleteInventoryBtn,
  selectedInventoryId,
  selectedItems,
  handleDrawerConfirm,
  selectedDrawerItems,
}) => {
  return (
    <>
      {isOpen && (
        <div className="flex justify-center drawer-bg">
          <div className="fixed bg-white top-0 right-0 z-40 w-full h-screen max-w-xs p-4 overflow-y-auto transition-transform">
            <h5 className="inline-flex items-center mb-6 text-sm font-semibold text-gray-500 uppercase dark:text-gray-400">
              New Product
            </h5>
            <button
              type="button"
              onClick={toggleDrawer}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5 ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <form>
              <div className="flex items-center justify-center">
                <ul className="flex flex-col items-left gap-4">
                  {inventoryData.map((item: any, index: number) => (
                    <li key={index} className="gap-4">
                      <input
                        type="checkbox"
                        id={`checkbox_${index}`}
                        name={`checkbox_${index}`}
                        className="mr-2"
                        style={{
                          accentColor: "rgb(255, 74, 23)",
                          backgroundColor: "white",
                        }}
                        onChange={() => handleDrawerCheckboxChange(item.name)}
                        checked={
                          selectedDrawerItems.includes(item.name) ||
                          (index === 0 && selectedDrawerItems.length === 0)
                        } 
                      />
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="space-y-4">
                <div></div>

                <div className="flex items-center justify-center gap-4">
                  <div>
                    <Popconfirm
                      title="Delete the product"
                      description="Are you sure to delete this Inventory?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => deleteInventoryBtn(selectedInventoryId)}
                    >
                      <button
                        type="button"
                        className="w-full bg-primary-700 text-[#ff4a17] py-2.5 px-4 rounded-lg text-sm border"
                      >
                        Remove
                      </button>
                    </Popconfirm>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="w-full bg-primary-700 text-[#ff4a17] py-2.5 px-4 rounded-lg  text-sm border"
                      onClick={handleDrawerConfirm}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default InventoryDrawer;
