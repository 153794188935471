import React, { useEffect, useState } from "react";
import UpgradeButton from "../UpgradeButton/UpgradeButton";

interface InventorySyncProps {
  isSidebarOpen: boolean;
}

export default function InventorySync({ isSidebarOpen }: InventorySyncProps) {
  const [inventoryData, setInventoryData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchInventoryData = async () => {
      try {
        const sellerId = localStorage.getItem("id");
        const response = await fetch(
          `https://gateway.dockify.co/SellerInventorySync/GetAll?SellerId=${sellerId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch inventory data");
        }
        const data = await response.json();
        if (data.ack === "Failure") {
          setError(data.messages.errors[0]);
        } else {
          setInventoryData(data.items);
        }
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInventoryData();
  }, []);

  return (
    <div className="px-4 sm:px-8 md:px-16 py-2">
      <div className="text-start mt-5 ">
        <span className="dockify-searchheading font-bold"> INVENTORY SYNC</span>
      </div>

      <UpgradeButton />
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8 rounded-lg">
            <div className="overflow-hidden">
              <table className="min-w-full text-md ">
                <thead className="bg-white">
                  <tr>
                    <th className="font-lg text-black border px-6 py-4 text-left">
                      Batch Id
                    </th>
                    <th className="font-lg text-black border px-6 py-4 text-left">
                      Total
                    </th>
                    <th className="font-lg text-black border px-6 py-4 text-left">
                      To Process
                    </th>
                    <th className="font-lg text-black border px-6 py-4 text-left">
                      Insert
                    </th>
                    <th className="font-lg text-black border px-6 py-4 text-left">
                      Revised
                    </th>
                    <th className="font-lg text-black border px-6 py-4 text-left">
                      Status
                    </th>
                    <th className="font-lg text-black border px-6 py-4 text-left">
                      Store Name
                    </th>
                    <th className="font-lg text-black border px-6 py-4 text-left">
                      Started On
                    </th>
                    <th className="font-lg text-black border px-6 py-4 text-left">
                      Completed On
                    </th>
                    <th className="font-lg text-black border px-6 py-4 text-left">
                      Process
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {inventoryData &&
                    inventoryData?.map((item: any, index: any) => (
                      <tr key={index} className="">
                        <td className="px-6 py-4 whitespace-nowrap font-medium text-black">
                          {item.batchId}
                        </td>
                        <td className="text-black font-light px-6 py-4 whitespace-nowrap">
                          {item.total}
                        </td>
                        <td className="text-black font-light px-6 py-4 whitespace-nowrap">
                          {item.toProcess}
                        </td>
                        <td className="text-black font-light px-6 py-4 whitespace-nowrap">
                          {item.insert}
                        </td>
                        <td className="text-black font-light px-6 py-4 whitespace-nowrap">
                          {item.revised}
                        </td>
                        <td className="text-black font-light px-6 py-4 whitespace-nowrap">
                          {item.status}
                        </td>
                        <td className="text-black font-light px-6 py-4 whitespace-nowrap">
                          {item.storeName}
                        </td>
                        <td className="text-black font-light px-6 py-4 whitespace-nowrap">
                          {item.startedOn}
                        </td>
                        <td className="text-black font-light px-6 py-4 whitespace-nowrap">
                          {item.completedOn}
                        </td>
                        <td className="text-black font-light px-6 py-4 whitespace-nowrap">
                          {item.process}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
