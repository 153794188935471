import React from "react";
import LoadingIndicator from "./LoadingIndicator";

interface LoadingOverlayProps {
  isLoading: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isLoading }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center ${
        isLoading ? "block" : "hidden"
      }`}
    >
      <LoadingIndicator />
    </div>
  );
};

export default LoadingOverlay;
