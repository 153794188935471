import React, { useState, useRef, useEffect } from "react";

interface NavbarProps {
  toggleSidebarMenu: () => void;
  selectAllProducts?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({
  toggleSidebarMenu,
  selectAllProducts,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node)
      ) {
        setIsDrawerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectAll = () => {
    if (selectAllProducts) {
      selectAllProducts();
    }
  };

  const handleFilterClick = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleDropdown = (index: number) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  const closeDropdown = () => {
    setActiveDropdown(null);
    setIsOpen(false);
  };

  return (
    <header>
      <div className="flex justify-between items-center p-2 px-4 lg:px-12">
        <div className="flex items-center ">
          <button
            onClick={toggleSidebarMenu}
            className="p-2 ml-2 lg:ml-4 rounded-md focus:outline-none focus:ring no-ring"
          >
            <svg
              className="w-4 h-4 text-black outline-none focus:outline-none"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 12h18M3 6h18M3 18h18"
              />
            </svg>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
