import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UpgradeButton from "../../UpgradeButton/UpgradeButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import ReactImageMagnify from "react-image-magnify";

interface Product {
  title: string;
  price: number;
  imageUrl: string[];
  subCategory: string;
  lineItemType: string;
}
interface ProductsPageProps {
  isSidebarOpen: boolean;
}

const ProductsPage: React.FC<ProductsPageProps> = ({ isSidebarOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPage = location.state?.currentPage || 1;
  const [productData, setProductData] = useState<Product | any>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { productId } = useParams<{ productId: string }>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://gateway.dockify.co/DockifyProduct/GetByProduct?Id=${productId}`
        );
        const data = await response.json();
        setProductData(data);
        if (data && data.list && data.list.length > 0) {
          setSelectedImage(data.list[0].imageUrl[0]);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchData();
  }, [productId]);

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
  };

  if (!productData) return null;

  const { title, price, imageUrl, subCategory, lineItemType } =
    productData.list[0];

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="px-4 sm:px-8 md:px-16 py-10">
      <UpgradeButton />
      <div className="border bg-white md:grid md:grid-cols-2 md:items-center md:px-32">
        <div className="md:flex md:items-center">
          {imageUrl && imageUrl.length > 0 && (
            <div className="p-3">
              {imageUrl.map((image: any, index: any) => (
                <div
                  key={index}
                  className="w-20"
                  onClick={() => handleImageClick(image)}
                >
                  <img
                    src={image}
                    alt={`${title} - Image ${index + 1}`}
                    className="border mt-3 cursor-pointer"
                  />
                </div>
              ))}
            </div>
          )}
          {selectedImage && (
            <div className="bg-white relative w-68">
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "Selected Image",
                    src: selectedImage,
                    width: 400,
                    height: 400,
                    isFluidWidth: true,
                  },
                  largeImage: {
                    src: selectedImage,
                    width: 1000,
                    height: 1000,
                  },
                  isFluidWidth: true,
                  isHintEnabled: true,
                }}
              />
            </div>
          )}
        </div>
        <div className="p-3 m-3 font-bold ">
          <p className="text-lg mt-3">
            {lineItemType} {subCategory}
          </p>
          <h2 className="mt-3 text-lg ">{title}</h2>
          <h2 className="mt-3">
            Ratings 4:7 <FontAwesomeIcon icon={faStar} />
          </h2>
          <p className="mt-3 text-lg">Price: ${price}</p>
          <p className="mt-3 text-sm">
            Diamonds are the purest or most pure, the strongest carbon, which is
            naturally polished in the isometric system. a piece of stone. a
            clear, flawless or almost flawless piece of stone, especially when
            cut and polished, valued as a precious stone.
          </p>
          <p className="mt-3 text-sm">
            It is a very difficult natural phenomenon known. It is also a very
            popular gemstone and due to its extreme durability, diamonds have a
            number of important industrial applications.
          </p>
          <div className="text-end">
            <button
              className="border bg-black text-white p-3 rounded hover:bg-white hover:text-black hover:border-black"
              onClick={handleBack}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductsPage;
