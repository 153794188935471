import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { message, notification } from "antd";
import { confirmClick } from "../Services/Service";

interface ModalProps {
  modalOpen: boolean;
  closeModal: (modalId: string) => void;
  tags: string[];
  handleAddTag: () => void;
  showTagInput: boolean;
  tagName: string;
  handleTagInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleConfirm: () => void;
  selectedItems: Set<number>;
  selectAll: boolean;
}

const Modal: React.FC<ModalProps> = ({
  modalOpen,
  closeModal,
  tags,
  handleAddTag,
  showTagInput,
  tagName,
  handleTagInputChange,
  handleConfirm,
  selectedItems,
  selectAll,
}) => {
  console.log(selectedItems);

  const [inputTagName, setInputTagName] = useState<any>("");
  const [fetchedTags, setFetchedTags] = useState<any>([]);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [tagDatas, setTagDatas] = useState<string | null>(null);
  const [showConfirmButton, setShowConfirmButton] = useState<boolean>(true);
  const [showTagInputData, setShowTagInputData] = useState<boolean>(true);

  const handleConfirmClick = async () => {
    try {
      if (fetchedTags.some((tag: any) => tag.name === inputTagName)) {
        message.error(
          "Tag name already exists. Please choose a different name."
        );
        return;
      }

      const selectedItemsArray = Array.from(selectedItems);
      const data = await confirmClick(selectedItemsArray, inputTagName);
      console.log(data);
      message.success(`Added successfully ${inputTagName}`);
      notification.success({
        message: `Added successfully ${inputTagName}`,
      });

      closeModal("modelConfirm");
      setInputTagName("");
    } catch (error) {
      console.log(error);
    }
    fetchInventoryData();
  };

  const handleTagClick = (tagData: any) => {
    setSelectedTag((prevTag) =>
      prevTag === tagData.name ? null : tagData.name
    );
    setInputTagName(tagData.name);
    setTagDatas(tagData.id);
    setShowConfirmButton(false);
    setShowTagInputData(false);
  };

  const handleAddTagClick = () => {
    setSelectedTag(null);
    setInputTagName("");
    setShowConfirmButton((prevState) => !prevState);
    setShowTagInputData((prevState) => !prevState);
  };

  function particularData(tagData: any, selectedItems: any) {
    const selectedItemsArray = Array.from(selectedItems);
    const sellerIdString = localStorage.getItem("id");
    const sellerId = sellerIdString ? parseInt(sellerIdString) : 0;
    const requestData = {
      productRequest: {
        id: tagDatas,
        inventoryId: tagDatas,
        inventoryName: inputTagName,
        sellerId: sellerId,
        dockifySKU: selectedItemsArray,
        createdOn: tagData.createdOn,
        isActive: true,
      },
    };
    console.log(requestData);
    fetch("https://gateway.dockify.co/SellerInventoryListProduct/Particular", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Response:", data);
        message.success(`Added successfully ${inputTagName}`);
        notification.success({
          message: `Added successfully ${inputTagName}`,
        });
        closeModal("modelConfirm");
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  }
  const fetchInventoryData = async () => {
    try {
      const sellerId = localStorage.getItem("id");
      const response = await fetch(
        `https://gateway.dockify.co/SellerInventoryList/SellerId?SellerId=${sellerId}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch inventory data");
      }
      const data = await response.json();
      console.log(data);
      setFetchedTags(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchInventoryData();
  }, []);

  return (
    <>
      {modalOpen && (
        <div
          id="modelConfirm"
          className="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
        >
          <div className="relative top-40 mx-auto shadow-xl rounded-md bg-white max-w-md">
            <div className="flex justify-end p-2">
              <button
                onClick={() => closeModal("modelConfirm")}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  ></path>
                </svg>
              </button>
            </div>

            <div className="p-10 pt-0 text-left">
              <h3 className="inline-block border-b font-bold border-black text-xl md:text-2xl lg:text-sm">
                Add to Inventory list
              </h3>
              <p className="text-sm mt-5 mb-6">
                You can add Tags to group your products. When adding Tags to
                products in batches, only one Tag can be selected.
              </p>
              <div className="flex flex-wrap ">
                <div className="flex flex-wrap ">
                  {fetchedTags.map((tagData: any, index: number) => (
                    <span
                      key={index}
                      className={`text-sm border border-gray-400 rounded p-2 mr-2 mb-6 cursor-pointer ${
                        selectedTag === tagData.name
                          ? "bg-[#ff4a17] text-white"
                          : ""
                      }`}
                      onClick={() => handleTagClick(tagData)}
                    >
                      {tagData.name}
                    </span>
                  ))}
                </div>
              </div>
              <div className="flex mt-6">
                <button
                  onClick={handleAddTagClick}
                  className="text-sm text-[#ff4a17] font-bold"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2 text-bold" />
                  Add a New Tag
                </button>
              </div>
              <div>
                {showTagInputData && (
                  <>
                    <input
                      type="text"
                      value={inputTagName}
                      onChange={(e) => setInputTagName(e.target.value)}
                      className="ml-2 mt-3 p-1 border border-gray-400 rounded outline-none"
                    />
                  </>
                )}
              </div>
              <div className="flex flex-col md:flex-row justify-end gap-4 md:gap-10 text-[#ff4a17] mt-6">
                <button className="border p-2 rounded mb-2 md:mb-0">
                  Skip
                </button>
                {showTagInputData && !selectedTag && (
                  <button
                    onClick={handleConfirmClick}
                    className="border p-2 rounded"
                  >
                    Confirm
                  </button>
                )}

                {selectedTag && (
                  <button
                    onClick={() => particularData(selectedTag, selectedItems)}
                    className="border p-2 rounded"
                  >
                    Confirm
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
